import { Mail } from "@mui/icons-material";
import { Avatar, Badge, Box, Stack, Typography } from "@mui/material";
import { getUnreadMessage } from "../utils";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { updateActiveConversation } from "store/Slices/ChatSlice";
import dumyUser from "../../../assets/images/dumyUser.png";
import { getChatMessages } from "store/Slices/ChatSlice";
import { useEffect, useState } from "react";
export const ConversationItem = ({ conversation, setMessagesLoading }) => {
  const dispatch = useDispatch();
  const { userId, activeConversation } = useSelector((state) => state.chats);
  const { id, members, is_group, last_message, unread_messages } = conversation;
  const [unreadCount, setUnreadCount] = useState(0);
  const isActive = activeConversation?.id === id;

  const handleChatClick = (conversation) => {
    if (conversation?.id == activeConversation?.id) return;
    const updatedUnreadMessages = conversation?.unread_messages?.map((message) =>
      message?.user_id === userId?.user_id ? { ...message, unread_messages: 0 } : message
    );
    let activeConversations = {
      ...conversation,
      unread_messages: updatedUnreadMessages,
      active: true,
    };
    dispatch(updateActiveConversation(activeConversations));
    dispatch(
      getChatMessages({
        id: conversation?.id,
        onSuccess: () => setMessagesLoading(false),
      })
    );
  };
  const formatMessageDate = (lastMessageAt) => {
    if (lastMessageAt) {
      const isToday = dayjs().isSame(dayjs(lastMessageAt + "Z"), "day");
      return isToday
        ? dayjs(lastMessageAt + "Z").format("h:mm a")
        : dayjs(lastMessageAt + "Z").format("MM/DD/YYYY ");
    }
  };
  useEffect(() => {
    if (activeConversation !== null) {
      const Count = getUnreadMessage(activeConversation?.unread_messages, userId?.user_id);
      setUnreadCount(Count);
    } else {
      const Count = getUnreadMessage(unread_messages, userId?.user_id);
      setUnreadCount(Count);
    }
  }, [activeConversation, conversation]);
  return (
    <Box
      key={id}
      onClick={() => {
        handleChatClick(conversation);
      }}
      className={` ${isActive ? "active-chat-color" : "chat-color"}`}
      sx={{
        my: 1,
        p: 1,
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        borderRadius: 2,
      }}
    >
      <Avatar
        src={members[0]?.image ? members[0]?.image : dumyUser}
        alt="Profile Picture"
        sx={{ width: 48, height: 48, mr: 2 }}
      />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ width: "100%" }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: 600, color: "#fff", mb: 0.5 }}
            className="truncate-line-1"
          >
            {members[0]?.name}
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: "#fff", fontSize: 14 }}
            className="truncate-line-1"
          >
            {last_message?.content != null || last_message?.media != null
              ? is_group
                ? last_message.sender_id === userId?.user_id
                  ? // If group and sender is the current user
                    `You: ${last_message?.content || "This message contains media content"}`
                  : // If group and sender is someone else
                    `${last_message?.sender_name?.split(" ")[0]}: ${
                      last_message?.content || "This message contains media content"
                    }`
                : // If not a group, show the message or media content
                  last_message?.content || "This message contains media content"
              : ""}
          </Typography>
        </Box>
        <Box sx={{ textAlign: "right", minWidth: 60 }}>
          <Typography
            variant="caption"
            sx={{ fontWeight: 400, fontSize: 12, color: "#fff" }}
            className="truncate-line-1"
          >
            {formatMessageDate(last_message?.created_at)}
          </Typography>
          {unread_messages?.some(
            (message) => message.user_id === userId?.user_id && message.unread_messages !== 0
          ) ? (
            <Badge
              sx={{ mt: 0.5 }}
              color="primary"
              badgeContent={unread_messages
                .filter(
                  (message) => message.user_id === userId?.user_id && message.unread_messages !== 0
                )
                .map((message) => message.unread_messages)}
              overlap="circular"
            >
              <Mail sx={{ color: "#fff", fontSize: 20 }} />
            </Badge>
          ) : null}
        </Box>
      </Stack>
    </Box>
  );
};
