import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { RepositoryFactory } from "repository/RepositoryFactory";
const roleRepo = RepositoryFactory.get("role");

export const getAllRole = createAsyncThunk("role/getAllRole", async (_, { rejectWithValue }) => {
  try {
    const { data } = await roleRepo.getAllRole();
    if (data.status_code === 200) {
      return data;
    }
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const addRole = createAsyncThunk(
  "role/addRole",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await roleRepo.addRole(payload);
      if (data.status_code === 200) {
        onSuccess();
        toast.success(data.detail);
        return data;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteRole = createAsyncThunk(
  "role/deleteRole",
  async ({ roleId, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await roleRepo.deleteRole(roleId);
      if (data.status_code === 200) {
        toast.success(data.detail);
        onSuccess();
        return data;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const deleteBulkRole = createAsyncThunk(
  "role/deleteBulkRole",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    try {
      const promises = payload.map(async (roleId) => {
        await roleRepo.deleteRole(roleId);
      });
      await Promise.all(promises);
      onSuccess();
      toast.success("Roles deleted successfully");
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const updateRole = createAsyncThunk(
  "role/updateRole",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    try {
      const response = await roleRepo.updateRole(payload);
      if (response.status === 200) {
        onSuccess();
        toast.success("Role Updated Successfully");
        return data;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getUserRoles = createAsyncThunk("role/userRoles", async (_, { rejectWithValue }) => {
  try {
    const { data } = await roleRepo.userRoles();
    if (data.status_code === 200) {
      return data;
    }
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateVisibleRole = createAsyncThunk(
  "role/updateVisibleRole",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await roleRepo.updateVisibleRole(payload);
      if (data.status_code === 200) {
        onSuccess();
        toast.success(data.detail);
        return data;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState = {
  loading: false,
  allRoles: [],
  userRoles: [],
};

const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //Add role
      .addCase(addRole.pending, (state) => {
        state.loading = true;
      })
      .addCase(addRole.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addRole.rejected, (state, action) => {
        state.loading = false;
      })
      //Get all roles
      .addCase(getAllRole.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllRole.fulfilled, (state, action) => {
        state.loading = false;
        state.allRoles = action.payload.data;
      })
      .addCase(getAllRole.rejected, (state, action) => {
        state.loading = false;
      })
      //Delete role
      .addCase(deleteRole.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteRole.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteRole.rejected, (state, action) => {
        state.loading = false;
      })
      //Delete Bulk roles
      .addCase(deleteBulkRole.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteBulkRole.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteBulkRole.rejected, (state, action) => {
        state.loading = false;
      })
      //Update role
      .addCase(updateRole.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateRole.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateRole.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getUserRoles.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserRoles.fulfilled, (state, action) => {
        state.loading = false;
        state.userRoles = action.payload.data;
      })
      .addCase(getUserRoles.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateVisibleRole.pending, (state) => {
        state.loading = true;
      });
    // .addCase(updateVisibleRole.fulfilled, (state, action) => {
    //   state.loading = false;
    // })
    // .addCase(updateVisibleRole.rejected, (state, action) => {
    //   state.loading = false;
    // });
  },
});
export default roleSlice.reducer;
