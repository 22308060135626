import { Box, Link, Stack, Typography } from "@mui/material";
import Attachment from "../../../assets/images/Attachment.png";
import pdfIcon from "../../../assets/images/pdfIcon.png";
import zipIcon from "../../../assets/images/zipIcon.png";
import txtIcon from "../../../assets/images/txtIcon.png";
import wordIcon from "../../../assets/images/wordIcon.png";
const getIconSrc = (fileType) => {
  //console.log(fileType, "fileType");
  switch (fileType) {
    case "pdf":
      return pdfIcon;
    case "x-zip-compressed":
    case "zip":
      return zipIcon;
    case "txt":
      return txtIcon;
    case "vnd.openxmlformats-officedocument.wordprocessingml.document":
    case "doc":
    case "docx":
      return wordIcon;
    default:
      return Attachment; // Default icon for other types
  }
};
export const MessageBubble = ({ message, time, position }) => (
  <Stack
    direction="row"
    alignItems="flex-start"
    gap={2}
    sx={{
      justifyContent: position === "right" ? "flex-end" : "flex-start",
      flexDirection: position === "right" ? "row-reverse" : "row",
    }}
    className={`message message-${position}`}
  >
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: position === "right" ? "flex-start" : "flex-start",
        gap: 0.5,
        width: "100%", // Ensures the width of the container
      }}
    >
      {/* Sender Name and Time on the Same Row */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%", // Ensures full width for space-between alignment
          alignItems: "center",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            whiteSpace: "nowrap",
            fontSize: "1.1rem",
            color: position === "right" ? "#fff" : "text.primary",
          }}
        >
          {message?.sender_name}
        </Typography>
        <Typography
          variant="caption"
          sx={{
            ml: 2,
            whiteSpace: "nowrap",
            color: position === "right" ? "#fff" : "text.primary",
          }}
        >
          {time}
        </Typography>
      </Box>

      {/* Message Content */}
      <Typography
        variant="body1"
        sx={{
          fontSize: "1rem",
          wordWrap: "break-word",
          mt: 0.5,
        }}
      >
        {message?.content}
      </Typography>

      {/* Media Content */}
      {message?.extension && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 1,
            width: "100%",
            maxHeight: "300px",
            overflow: "hidden", // Prevent overflow of the container
          }}
        >
          {/* Check for image types */}
          {["jpg", "jpeg", "png", "gif", "svg"].includes(message?.extension) && (
            <Box
              component="img"
              src={message?.media}
              alt="media"
              sx={{
                maxWidth: "100%", // Ensure the image doesn't exceed the container width
                maxHeight: "300px", // Limit image height
                objectFit: "cover", // Maintain aspect ratio and prevent distortion
                borderRadius: 2,
              }}
            />
          )}

          {/* Check for video types */}
          {["mp4", "webm"].includes(message?.extension) && (
            <Box
              component="video"
              controls
              sx={{
                width: "100%",
                maxHeight: "300px",
                objectFit: "cover", // Prevent video from distorting
              }}
            >
              <source src={message?.media} type={`video/${message?.extension}`} />
              Your browser does not support the video tag.
            </Box>
          )}
          {/* Check for file types like PDF, ZIP, DOCX, etc. */}
          {[
            "pdf",
            "zip",
            "txt",
            "docx",
            "vnd.openxmlformats-officedocument.wordprocessingml.document",
          ].includes(message?.extension) && (
            <Link
              href={message?.media}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                textDecoration: "none",
                cursor: "pointer",
                mt: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  component="img"
                  src={getIconSrc(message?.extension)} // Get the icon based on the file extension
                  alt="File Icon"
                  sx={{
                    height: "8rem",
                    width: "8rem",
                    mt: 1,
                    mr: 2,
                  }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    mt: 2,
                    fontSize: "1rem",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}
                  title={message?.filename}
                >
                  {message?.filename
                    ? `${message?.filename.split("_")[1]?.slice(0, 20)}`
                    : "Attachment"}
                </Typography>
              </Box>
            </Link>
          )}
        </Box>
      )}
    </Box>
  </Stack>
);
