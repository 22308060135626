import { Card } from "@mui/material";
import Modal from "@mui/material/Modal";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import ReactMarkdown from "react-markdown";

const FeedbackModal = ({ modal, handleClose, interviewFeedback }) => {
  console.log(interviewFeedback, "interviewFeedback?.averageScore");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    maxHeight: "80vh", // Limits the height to 80% of the viewport height
    boxShadow: 24,
    p: 4,
    overflowY: "auto", // Enables scrolling when content overflows
  };

  return (
    <div>
      <Modal
        open={modal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={style}>
          <MDBox>
            <MDTypography variant="h6">Interview Feedback</MDTypography>
          </MDBox>
          <MDBox mx={1} p={1} mt={1}>
            {interviewFeedback &&
              interviewFeedback.feedbackList?.map((item) => {
                return (
                  <>
                    <MDBox>
                      <MDTypography variant="h5" m={1}>
                        Question:
                      </MDTypography>
                      <hr />
                      <MDTypography variant="h6" my={2} mx={1}>
                        {item.question}
                      </MDTypography>
                      <hr />
                      <MDTypography variant="h5" color="info" m={1}>
                        Answer:
                      </MDTypography>
                      <hr />
                      <MDTypography variant="h6" my={2} mx={1}>
                        {item?.answer || "Not Answered"}
                      </MDTypography>
                      <hr />
                      <MDTypography variant="h5" color="info" m={1}>
                        Feedback:
                      </MDTypography>
                      <MDTypography variant="h6" my={2} mx={1}>
                        <ReactMarkdown>{item?.feedback}</ReactMarkdown>
                      </MDTypography>
                    </MDBox>
                  </>
                );
              })}
            <MDBox
              mt={3}
              sx={{
                display: "flex",
                gap: "10px",
                justifyContent: "start",
                alignItems: "center",
                background: "#9eeaf9",
                padding: "10px",
                borderRadius: "12px",
              }}
            >
              <h5 className="my-0">Nexa Grading Score:</h5>
              <span className="score">
                {interviewFeedback?.score ? parseFloat(interviewFeedback?.score).toFixed(2) : 0}
              </span>
            </MDBox>
            <MDBox mt={4} mb={1} sx={{ display: "flex", gap: "10px", justifyContent: "end" }}>
              <MDButton variant="gradient" color="info" onClick={handleClose}>
                Close
              </MDButton>
            </MDBox>
          </MDBox>
        </Card>
      </Modal>
    </div>
  );
};

export default FeedbackModal;
