import { Box } from "@mui/material";
import { useEffect, useRef } from "react";

const MessageInput = ({ value, sendLoader, onChange }) => {
  const textareaRef = useRef(null);

  useEffect(() => {
    adjustTextareaHeight();
  }, [value]);

  //TODO FOR AQIB: minimize the height of text area after scroll
  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const handleChange = (e) => {
    onChange(e);
    adjustTextareaHeight();
  };

  return (
    <Box
      sx={{
        mx: 2,
        py: 2,
      }}
      className="message-input-container"
    >
      <textarea
        ref={textareaRef}
        id="prompt-textarea"
        tabIndex="0"
        data-id="root"
        rows={1}
        placeholder="Send a message"
        className="message-input"
        value={value}
        onChange={handleChange}
        onKeyDown={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault(); // Prevent new line
            if (!sendLoader) {
              e.target.form.requestSubmit(); // Trigger form submission
            }
          }
        }}
      />
    </Box>
  );
};

export default MessageInput;
