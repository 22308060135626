import {
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import UserImg from "../../../assets/images/logos/user.png";
import TaskChatSection from "components/TaskChatSection";
import { useDispatch, useSelector } from "react-redux";
import { getSingleTask } from "store/Slices/TaskSlice";
import { useNavigate, useParams } from "react-router-dom";
import { getJoinedUsers } from "store/Slices/usersSlice";
import Loader from "components/Loader";
import { getRouteByNumber } from "context";
import { permission } from "context";
import { featurePermissionsObject } from "context";
import { addComment } from "store/Slices/TaskSlice";
import { toast } from "react-toastify";
import { udpateTaskStatus } from "store/Slices/TaskSlice";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { udpateTask } from "store/Slices/TaskSlice";
import dayjs from "dayjs";
import UndoIcon from "@mui/icons-material/Undo";

const TaskDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const spiltID = id?.split("_")?.[0];
  const { getSingleTaskData, loading } = useSelector((state) => state.task);
  const { user } = useSelector((state) => state.auth);
  const { orgTheme } = useSelector((state) => state.oraganizationTheme);
  const [storePermissions, setStorePermissions] = useState(null);
  const [query, setQuery] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [pioAnchorEl, setPioAnchorEl] = useState(null);
  const parsedObject = JSON.parse(user?.rolePermissions);
  let pagePermissions;
  let featurePermissions;
  if (parsedObject) {
    const [key, value] = Object.entries(parsedObject)[0];
    pagePermissions = key;
    featurePermissions = value;
  }

  const handleDisabled = (val) => {
    if (storePermissions) {
      if (storePermissions.hasOwnProperty(val)) {
        return !storePermissions[val];
      }
      return null;
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (status) => {
    if (status) {
      handleUpdateStatus(status);
    }
    setAnchorEl(null);
  };

  const handlePiorityClick = (event) => {
    setPioAnchorEl(event.currentTarget);
  };

  const handlePiorityClose = (piority) => {
    if (piority) {
      const formData = {
        id: spiltID,
        title: getSingleTaskData?.task_details?.title,
        description: getSingleTaskData?.task_details?.description,
        duedate: dayjs(getSingleTaskData?.task_details?.due_date).format("DD/MM/YYYY"),
        priority: piority,
        assignto: getSingleTaskData?.assigned_user_list?.map((user) =>
          user?.customer_id.toString()
        ),
        link: getSingleTaskData?.task_details?.link,
        task_file: getSingleTaskData?.task_details?.task_file,
      };
      console.log(formData, "payload");
      dispatch(
        udpateTask({
          formData,
          onSuccess: () => {
            dispatch(
              getSingleTask({
                payload: spiltID,
                onSuccess: (res) => {},
              })
            );
            toast.success("Priority updated successfully");
          },
        })
      );
    }
    setPioAnchorEl(null);
  };

  const handleUpdateStatus = (updatedStatus) => {
    // if (getSingleTaskData?.assigned_user_list?.[0]?.task_status == "COMPLETED") {
    //   toast.error("Task is already completed");
    //   return;
    // }
    dispatch(
      udpateTaskStatus({
        taskID: spiltID,
        status: updatedStatus,
        onSuccess: () => {
          dispatch(
            getSingleTask({
              payload: spiltID,
              onSuccess: (res) => {},
            })
          );
          toast.success("Task status updated successfully");
        },
      })
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!query) {
      error("Please enter your comment");
      return;
    }
    dispatch(
      addComment({
        taskID: spiltID,
        comment: query,
        onSuccess: (res) => {
          toast.success(res);
          setQuery("");
          dispatch(
            getSingleTask({
              payload: spiltID,
              onSuccess: (res) => {},
            })
          );
        },
      })
    );
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "COMPLETED":
        return "#28a745";
      case "IN_PROGRESS":
        return "#ffc107";
      case "NOT_STARTED":
        return "#DC3545";
      default:
        return "#000"; // Default color if no status matches
    }
  };

  const getPiorityColor = (status) => {
    switch (status) {
      case "HIGH":
        return "#DC3545";
      case "LOW":
        return "#28a745";
      case "MEDIUM":
        return "#ffc107";
      default:
        return "#000"; // Default color if no status matches
    }
  };

  useEffect(() => {
    if (pagePermissions !== permission.admin && pagePermissions !== permission.tasks) {
      const routeLink = getRouteByNumber(pagePermissions);
      return navigate(routeLink);
    }
    if (featurePermissions) {
      const data = featurePermissionsObject(featurePermissions);
      setStorePermissions(data);
    }
  }, []);

  useEffect(() => {
    dispatch(
      getSingleTask({
        payload: spiltID,
        onSuccess: (res) => {},
      })
    );
    dispatch(getJoinedUsers());
  }, []);

  return (
    <>
      <DashboardLayout>
        {loading && <Loader />}
        <Card sx={{ p: 3 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    float: "right",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      color: "#fff",
                      bgcolor: "info",
                      "&:hover": {
                        bgcolor: "darkslategray",
                      },
                    }}
                    onClick={() => navigate(-1)}
                  >
                    <UndoIcon fontSize="large" sx={{ mt: -0.25, mr: 0.5 }} />
                    Back
                  </Button>
                  <div className="flex gap-3">
                    <Button
                      id="dropdownButton"
                      aria-controls={Boolean(anchorEl) ? "dropdown-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={Boolean(anchorEl) ? "true" : undefined}
                      onClick={handleClick}
                      variant="contained"
                      sx={{
                        backgroundColor: getStatusColor(
                          getSingleTaskData?.assigned_user_list?.[0]?.task_status
                        ),
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                        marginRight: "10px",
                      }}
                    >
                      {getSingleTaskData?.assigned_user_list?.[0]?.task_status == "NOT_STARTED"
                        ? "Todo"
                        : getSingleTaskData?.assigned_user_list?.[0]?.task_status == "IN_PROGRESS"
                        ? "In Progress"
                        : getSingleTaskData?.assigned_user_list?.[0]?.task_status == "COMPLETED"
                        ? "Completed"
                        : ""}
                      <ArrowDropDownIcon sx={{ ml: 1 }} />
                    </Button>
                    <Menu
                      id="dropdown-menu"
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={() => handleClose()}
                      sx={{
                        "& .MuiPaper-root": {
                          borderRadius: "8px",
                          boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
                        },
                      }}
                    >
                      <MenuItem onClick={() => handleClose("NOT_STARTED")}>Todo</MenuItem>
                      <MenuItem onClick={() => handleClose("IN_PROGRESS")}>In Progress</MenuItem>
                      <MenuItem onClick={() => handleClose("COMPLETED")}>Completed</MenuItem>
                    </Menu>
                    {/* /////piority button */}
                    <Button
                      id="dropdownButton"
                      aria-controls={Boolean(pioAnchorEl) ? "dropdown-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={Boolean(pioAnchorEl) ? "true" : undefined}
                      onClick={handlePiorityClick}
                      variant="contained"
                      sx={{
                        backgroundColor: getPiorityColor(getSingleTaskData?.task_details?.priority),
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {getSingleTaskData?.task_details?.priority == "HIGH"
                        ? "High"
                        : getSingleTaskData?.task_details?.priority == "MEDIUM"
                        ? "Medium"
                        : getSingleTaskData?.task_details?.priority == "LOW"
                        ? "Low"
                        : ""}
                      <ArrowDropDownIcon sx={{ ml: 1 }} />
                    </Button>
                    <Menu
                      id="dropdown-menu"
                      anchorEl={pioAnchorEl}
                      open={Boolean(pioAnchorEl)}
                      onClose={() => handlePiorityClose()}
                      sx={{
                        "& .MuiPaper-root": {
                          borderRadius: "8px",
                          boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
                        },
                      }}
                    >
                      <MenuItem onClick={() => handlePiorityClose("LOW")}>Low</MenuItem>
                      <MenuItem onClick={() => handlePiorityClose("MEDIUM")}>Medium</MenuItem>
                      <MenuItem onClick={() => handlePiorityClose("HIGH")}>High</MenuItem>
                    </Menu>
                  </div>
                </Box>
              </Box>

              <Typography
                variant="h4"
                sx={{
                  mb: 0,
                  fontWeight: "medium",
                  fontSize: "24px",
                  color: "#000",
                  textTransform: "capitalize",
                  mt: 1,
                }}
              >
                {getSingleTaskData?.task_details?.title}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  my: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    justifyContent: "center",
                  }}
                >
                  <Avatar
                    src={orgTheme?.file_url || UserImg}
                    alt="admin"
                    sx={{ width: 50, height: 50, borderRadius: "50%" }}
                  />
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "medium", color: "#000", textTransform: "capitalize" }}
                  >
                    {getSingleTaskData?.task_details?.organization_name}&nbsp;
                  </Typography>
                </Box>

                <Typography
                  variant="h6"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "400",
                    color: "#000",
                  }}
                >
                  - Due-Date:&nbsp;
                  {moment(getSingleTaskData?.task_details?.created_at).format("DD-MM-YYYY")}
                </Typography>
              </Box>

              <Typography
                variant="h6"
                sx={{
                  fontWeight: "400",
                  mt: 1,
                  color: "#000",
                  textAlign: "justify",
                  lineHeight: 1.5,
                  // fontSize: "16px",
                }}
              >
                {getSingleTaskData?.task_details?.description}
              </Typography>

              {getSingleTaskData?.task_details?.task_file && (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="h5" sx={{ mb: 2, color: "#000", fontWeight: "bold" }}>
                    File:
                  </Typography>
                  <Box
                    sx={{
                      position: "relative",
                      display: "inline-block",
                      pl: 4,
                      mt: -2,
                    }}
                  >
                    <img
                      src="https://www.iconpacks.net/icons/2/free-file-icon-1453-thumb.png"
                      alt="file-icon"
                      height={100}
                      width={100}
                    />
                    <a
                      href={getSingleTaskData?.task_details?.task_file}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IconButton
                        disabled={handleDisabled("create")}
                        sx={{
                          position: "absolute",
                          right: 0,
                          top: -15,
                          left: "77%",
                          cursor: "pointer",
                          display: "block",
                        }}
                      >
                        <CloudDownloadOutlinedIcon fontSize="large" />
                      </IconButton>
                    </a>
                  </Box>
                </Box>
              )}

              {getSingleTaskData?.task_details?.link && (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="h5" sx={{ mb: 2, color: "#000", fontWeight: "bold" }}>
                    Link:
                  </Typography>
                  <Box
                    sx={{
                      position: "relative",
                      display: "inline-block",
                      pl: 4,
                      mt: -2,
                    }}
                  >
                    <a
                      href={getSingleTaskData?.task_details?.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {getSingleTaskData?.task_details?.link}
                    </a>
                  </Box>
                </Box>
              )}

              {getSingleTaskData?.completed_task_attachments?.length > 0 && (
                <>
                  <Typography variant="h5" sx={{ mb: 2, mt: 2, color: "#000", fontWeight: "bold" }}>
                    Attachments:
                  </Typography>
                  <Box sx={{ display: "flex", gap: 3, flexWrap: "wrap" }}>
                    {getSingleTaskData?.completed_task_attachments?.map((attachment) => (
                      <Box
                        sx={{
                          backgroundColor: "#f7f7f7",
                          py: 2,
                          borderRadius: "12px",
                          mt: 1,
                          minWidth: "350px",
                          width: "auto",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            gap: 1,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "30%",
                            }}
                          >
                            <Avatar
                              src={attachment?.image_url || UserImg}
                              alt="Profile Image"
                              sx={{ width: 60, height: 60 }}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              width: "70%",
                            }}
                          >
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Typography
                                variant="h6"
                                component="span"
                                sx={{ color: "#000", fontWeight: 500 }}
                              >
                                {attachment?.customer_name}
                              </Typography>
                              <Typography
                                variant="h6"
                                component="span"
                                sx={{ color: "#000", fontWeight: 400 }}
                              >
                                &nbsp;-&nbsp;
                                {moment(attachment?.created_at).format("hh:mm A")}
                              </Typography>
                            </Box>
                            <Typography
                              variant="body1"
                              sx={{
                                color: "#000",
                                mt: { xs: 0 },
                                fontWeight: 500,
                                lineHeight: 1.5,
                              }}
                            >
                              <Box
                                sx={{
                                  position: "relative",
                                  display: "inline-block",
                                  pl: 0,
                                  mt: 0,
                                }}
                              >
                                <img
                                  src="https://www.iconpacks.net/icons/2/free-file-icon-1453-thumb.png"
                                  alt="file-icon"
                                  height={50}
                                  width={50}
                                />
                                <a
                                  href={attachment?.task_file}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <IconButton
                                    disabled={handleDisabled("create")}
                                    sx={{
                                      position: "absolute",
                                      right: 0,
                                      top: -15,
                                      left: "70%",
                                      cursor: "pointer",
                                      display: "block",
                                    }}
                                  >
                                    <CloudDownloadOutlinedIcon fontSize="small" />
                                  </IconButton>
                                </a>
                              </Box>
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </>
              )}
            </Box>
          </Box>
          <Box sx={{ mt: 4 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                my: 2,
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  mb: 2,
                  color: "#000",
                  fontWeight: "bold",
                  fontSize: "22px",
                  textTransform: "capitalize",
                }}
              >
                {getSingleTaskData?.comments?.length} Comments
              </Typography>
              {/* <FilterDropdown /> */}
            </Box>

            <Box
              sx={{
                maxHeight: "70vh",
                overflow: "auto",
              }}
            >
              <TaskChatSection />
            </Box>
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
              gap: { xs: 5, sm: 2 },
              mt: 2,
            }}
          >
            <Typography variant="h6" sx={{ color: "#000" }}>
              Leave a comment here:
            </Typography>
          </Box>

          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              mt: 1,
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: "100%",
            }}
          >
            <TextareaAutosize
              minRows={5}
              placeholder="Type..."
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              style={{
                width: "100%",
                padding: "16px",
                border: "1px solid black",
                borderRadius: "16px",
              }}
            />
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  py: 1,
                  px: 3,
                  borderRadius: 2,
                  backgroundColor: "000",
                  color: "#fff",
                }}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Card>
      </DashboardLayout>
    </>
  );
};

export default TaskDetail;
