import { Box, TextField, TextareaAutosize } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import React from "react";
import Select from "react-select";
import DropDowns from "./DropDowns";
import { useDispatch, useSelector } from "react-redux";
import { importQuestion } from "store/Slices/questionsSlice";
import { getQuestions } from "store/Slices/questionsSlice";
const bodyStyle = {
  height: "auto",
};
export default function ImportQuestions({
  importQuestions,
  setImportQuestions,
  rolesDetails,
  categoriesDetails,
  setAddModalOpen,
  debouncedSearch,
  questionsType,
  page,
}) {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.questions);
  const handleQuestionFileChange = (event) => {
    const updatedQuestions = { ...importQuestions, file: event.target.files[0] };
    setImportQuestions(updatedQuestions);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("file", importQuestions.file);
    formData.append("role_category_id", importQuestions.role_category_id);
    formData.append("question_type_id", importQuestions.question_type_id);
    dispatch(
      importQuestion({
        payload: formData,
        onSuccess: () => {
          setImportQuestions({ question_type_id: "", role_category_id: "", file: "", roleId: "" });
          dispatch(getQuestions({ page: page, search: debouncedSearch }));
        },
      })
    );
    setAddModalOpen(false);
  };
  return (
    <form onSubmit={handleSubmit}>
      <Box sx={bodyStyle}>
        <Box mb={2}>
          <DropDowns
            rolesDetails={rolesDetails}
            question={importQuestions}
            categoriesDetails={categoriesDetails}
            Questions={importQuestions}
            setQuestions={setImportQuestions}
            index={""}
            questionsType={questionsType}
          />
          <input
            type="file"
            accept=".csv, .xlsx"
            onChange={(event) => {
              if (event.target.files.length > 0) {
                handleQuestionFileChange(event);
              }
            }}
            style={{ width: "100%", marginTop: "10px", padding: "10px" }}
            required
          />
        </Box>
      </Box>
      <MDBox mt={4} mb={1} sx={{ display: "flex", gap: "10px", justifyContent: "end" }}>
        <MDButton
          variant="gradient"
          color="info"
          onClick={() => setAddModalOpen(false)}
          disabled={loading}
        >
          Cancel
        </MDButton>
        <MDButton variant="gradient" color="info" type="submit" disabled={loading}>
          {loading ? "Loading..." : "Submit"}
        </MDButton>
      </MDBox>
    </form>
  );
}
