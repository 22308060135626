import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addUserSocket } from "config/socketConfig";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import { createOrganization, signInUser, otpStatus } from "./authThunk";
import { RepositoryFactory } from "repository/RepositoryFactory";
const authRepo = RepositoryFactory.get("auth");
const subOrgRepo = RepositoryFactory.get("subOrg");
const dashRepo = RepositoryFactory.get("Dashboard");

export const createOrganization = createAsyncThunk(
  "auth/createOrganization",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    try {
      delete payload.comfirmPassword;
      const { data } = await authRepo.createNewOraganization(payload);
      if (data.status_code === 200) {
        addUserSocket(data.accessToken);
        onSuccess();
        return data;
      }
    } catch (err) {
      toast.error(err?.response?.data?.detail);
      return rejectWithValue(err);
    }
  }
);
export const loginOrganization = createAsyncThunk(
  "auth/loginOrganization",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await authRepo.authenticateOrg(payload);
      if (data.status_code === 200) {
        addUserSocket(data.accessToken);
        onSuccess();
        return data;
      }
    } catch (err) {
      toast.error(err?.response?.data?.detail);
      return rejectWithValue(err);
    }
  }
);
export const resetPasswordAction = createAsyncThunk(
  "auth/resetPasswordAction",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await authRepo.resetPassword(payload);
      // console.log(data, "reset Data");
      if (data.status_code === 200) {
        toast.success(data?.detail);
        return data;
      }
    } catch (err) {
      toast.error(err?.response?.data?.detail);
      return rejectWithValue(err);
    }
  }
);
export const sendResetLinkAction = createAsyncThunk(
  "auth/sendResetLinkAction",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await authRepo.resetPasswordLink(payload);
      toast.success(data?.details);
      return data;
    } catch (err) {
      toast.error(err?.response?.data?.detail);
      return rejectWithValue(err);
    }
  }
);
export const logoutAction = createAsyncThunk("auth/logout", async (_, { rejectWithValue }) => {
  try {
    const { data } = await authRepo.logout();
    if (data?.status_code == 200) {
      return data.data;
    }
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getSubscription = createAsyncThunk(
  "subscription/getSubscription",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.getSubscription();
      if (data.activeBundle != null) {
        return data;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// export const firstTimeLogin = createAsyncThunk(
//   "auth/firstTimeLogin",
//   async (payload, { rejectWithValue }) => {
//     try {
//       // console.log({ payload });
//       return payload;
//       // const { data } = await authRepo.firstTimeLogin(payload);
//       // if (data.status_code === 200) {
//       //   return data;
//       // }
//     } catch (err) {
//       return rejectWithValue(err);
//     }
//   }
// );
export const activeFreeTrial = createAsyncThunk(
  "subscription/activeFreeTrial",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.activeFreeTrial();
      if (data?.status_code == 200) {
        return data?.data;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const reActivatePackage = createAsyncThunk(
  "subscription/reActivatePackage",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.reActivatePackage(payload);
      if (data?.status_code == 200) {
        toast.success(data?.detail);
        if (data?.data?.payment_intent_status == "succeeded") {
          onSuccess();
        }
        return data;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const RegisterSubAdmin = createAsyncThunk(
  "admin/registerSubadmin",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await authRepo.Register_subAdmin(payload);
      // onSuccess(data?.detail);
      if (data.status_code == 200) {
        toast.success(data?.detail);
        return data;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const registerSubOrganization = createAsyncThunk(
  "admin/registerSubOrganization",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await subOrgRepo.register(payload);
      // onSuccess(data?.detail);
      if (data.status_code == 200) {
        toast.success(data?.detail);
        return data;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState = {
  user: null,
  loading: false,
  logoutLoading: false,
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    firstTimeLogin: (state, action) => {
      state.user.activeBundle = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendResetLinkAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendResetLinkAction.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(sendResetLinkAction.rejected, (state) => {
        state.loading = false;
      })
      .addCase(resetPasswordAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(resetPasswordAction.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(resetPasswordAction.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createOrganization.pending, (state) => {
        // console.log("Running");
        state.loading = true;
        state.error = null;
      })
      .addCase(createOrganization.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        localStorage.setItem("auth", action.payload.accessToken);
        localStorage.setItem("refreshAuth", action.payload.refreshToken);
      })
      .addCase(createOrganization.rejected, (state, action) => {
        // console.log("Error");
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(loginOrganization.pending, (state) => {
        // console.log("Running");
        state.loading = true;
        state.error = null;
      })
      .addCase(loginOrganization.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        // state.user.rolePermissions = JSON.parse('{"100": 31}');
        localStorage.setItem("auth", action.payload.accessToken);
        localStorage.setItem("refreshAuth", action.payload.refreshToken);
        toast.success("Logged In Sucessfully.");
      })
      .addCase(loginOrganization.rejected, (state, action) => {
        // console.log("Error");
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getSubscription.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSubscription.fulfilled, (state, action) => {
        state.loading = false;
        const { activeBundle, data, is_trial_consumed } = action.payload;
        state.user.activeBundle = activeBundle;
        state.user.cancelation = data.cancel_at_period_end;
        state.user.cancelation_time = data.current_period_end;
        state.user.subscriptionStatus = data.status;
        state.user.is_trial_consumed = is_trial_consumed;
      })
      .addCase(getSubscription.rejected, (state) => {
        state.loading = false;
      })
      .addCase(activeFreeTrial.fulfilled, (state, action) => {
        state.user.activeBundle = "QolEraPnrw";
      })
      .addCase(logoutAction.pending, (state) => {
        state.logoutLoading = true;
      })
      .addCase(logoutAction.fulfilled, (state) => {
        state.logoutLoading = false;
        console.log("Logout action fulfilled");
        state.user = null;
        localStorage.removeItem("auth");
      })
      .addCase(logoutAction.rejected, (state) => {
        state.logoutLoading = false;
      })
      .addCase(reActivatePackage.pending, (state) => {
        state.loading = true;
      })
      .addCase(reActivatePackage.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(reActivatePackage.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(RegisterSubAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(RegisterSubAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        localStorage.setItem("auth", action.payload.accessToken);
        localStorage.setItem("refreshAuth", action.payload.refreshToken);
        // toast.success("Register Sucessfully.");
      })
      .addCase(RegisterSubAdmin.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(registerSubOrganization.pending, (state) => {
        state.loading = true;
      })
      .addCase(registerSubOrganization.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        localStorage.setItem("auth", action.payload.accessToken);
        localStorage.setItem("refreshAuth", action.payload.refreshToken);
        // toast.success("Register Sucessfully.");
      })
      .addCase(registerSubOrganization.rejected, (state, action) => {
        state.loading = false;
      });
    // .addCase(firstTimeLogin.fulfilled, (state, action) => {
    //   state.user.activeBundle = action.payload;
    // })
    // .addCase(firstTimeLogin.rejected, (state, action) => {
    //   state.user.activeBundle = null;
    // });
  },
});
export const { firstTimeLogin } = authSlice.actions;
export default authSlice.reducer;
