import { Button } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import jsPDF from "jspdf";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getUserResume } from "store/Slices/usersSlice";
import CvDesign from "./CvDesign";
import html2canvas from "html2canvas";
import { getSingleResume } from "store/Slices/usersSlice";
import Loader from "components/Loader";
import data from "layouts/Subscriptions/data/projectsTableData";
import { getResumeFeedback } from "store/Slices/usersSlice";
import FeedbackModal from "examples/Modal/FeedbackModal";
import ResumeFeedbackModal from "./ResumeFeedbackModal";

const UserResume = ({ tempRef, storePermissions }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const spiltID = id?.split("_")?.[0];
  const spiltName = id?.split("_")?.[1];
  const { userResume, loading } = useSelector((state) => state.users);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [storeResumeFeedback, setStoreResumeFeedback] = useState(null);

  tempRef = useRef();

  const handleToggleModal = () => {
    setIsOpen(false);
  };

  const handleDisabled = (val) => {
    if (storePermissions) {
      if (storePermissions.hasOwnProperty(val)) {
        return !storePermissions[val];
      }
      return null;
    }
  };

  const handleDownload = async (onSuccess) => {
    try {
      const element = tempRef.current;
      element.style.marginTop = "50rem";
      element.style.visibility = "visible";
      const canvas = await html2canvas(element, { scale: 2 });
      element.style.marginTop = "0rem";
      element.style.visibility = "hidden";
      const data = canvas.toDataURL("image/png");

      const pdf = new jsPDF();
      const imgProperties = pdf.getImageProperties(data);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
      const margin = 10;
      let heightLeft = pdfHeight;
      let position = 0;
      pdf.addImage(data, "PNG", 0, position, pdfWidth, pdfHeight + margin);
      heightLeft -= pdf.internal.pageSize.getHeight();

      while (heightLeft > 0) {
        position = heightLeft - pdfHeight + margin;
        pdf.addPage();
        pdf.addImage(data, "PNG", 0, position, pdfWidth, pdfHeight);
        heightLeft -= pdf.internal.pageSize.getHeight();
      }

      // Open PDF in new tab
      const pdfBlob = pdf.output("blob");
      const pdfUrl = URL.createObjectURL(pdfBlob);
      window.open(pdfUrl, "_blank");

      pdf.save(`${spiltName}.pdf`);

      onSuccess();
    } catch (error) {
      onSuccess();
    }
  };

  const handleDownlaodCv = (id) => {
    setLoader(true);
    dispatch(
      getSingleResume({
        id,
        onSuccess: () => {
          const timer = setTimeout(() => {
            handleDownload(() => {
              setLoader(false);
            });
          }, 1000);
          return () => clearTimeout(timer);
        },
        onError: () => {
          setLoader(false);
        },
      })
    );
  };

  const handleGetFeedback = (id) => {
    dispatch(
      getResumeFeedback({
        id,
        onSuccess: (res) => {
          setStoreResumeFeedback(res?.data);
          setIsOpen(true);
        },
      })
    );
  };

  useEffect(() => {
    setColumns([
      { Header: "Name", accessor: "name", align: "left" },
      { Header: "Email", accessor: "email", align: "center" },
      { Header: "Resume Name", accessor: "r_name", align: "center" },
      { Header: "Job Role", accessor: "job_role", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ]);
    // add user array
    const customAddUser =
      userResume &&
      userResume.length >= 1 &&
      userResume
        .filter((filterData) => filterData?.contact)
        .map((data, index) => {
          console.log(data, "mapData");
          return {
            name: (
              <MDTypography
                component="a"
                href="#"
                variant="button"
                color="text"
                fontWeight="medium"
                key={index + 100}
              >
                {data?.contact?.first_name} {data?.contact?.last_name}
              </MDTypography>
            ),
            email: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {data?.contact?.email}
              </MDTypography>
            ),
            r_name: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {data?.resume_name}
              </MDTypography>
            ),
            job_role: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {data?.job_role}
              </MDTypography>
            ),
            action: (
              <MDBox sx={{ display: "flex", justifyContent: "start", alignItems: "start" }}>
                {data?.isCompleted && (
                  <Button
                    variant="contained"
                    size="small"
                    disabled={handleDisabled("update") || loading}
                    sx={{
                      ml: 1.3,
                      color: "#fff",
                      bgcolor: "info",
                      "&:hover": {
                        bgcolor: "darkslategray",
                      },
                    }}
                    onClick={() => {
                      handleGetFeedback(data?.id);
                    }}
                  >
                    Get Feedback
                  </Button>
                )}
                <Button
                  variant="contained"
                  size="small"
                  disabled={handleDisabled("update") || loading}
                  sx={{
                    ml: 1.3,
                    color: "#fff",
                    bgcolor: "info",
                    "&:hover": {
                      bgcolor: "darkslategray",
                    },
                  }}
                  onClick={() => {
                    handleDownlaodCv(data?.id);
                  }}
                >
                  Get PDF
                </Button>
              </MDBox>
            ),
          };
        });
    console.log(customAddUser, "customAddUser");
    setRows(Array.isArray(customAddUser) ? customAddUser : []);
  }, [userResume]);

  useEffect(() => {
    dispatch(getUserResume({ spiltID }));
  }, []);

  return (
    <>
      {loader && <Loader />}
      {loading && <Loader />}
      <MDBox pt={3}>
        {userResume && userResume.length >= 1 && userResume[0].contact ? (
          <DataTable
            table={{ columns, rows }}
            isSorted={false}
            entriesPerPage={false}
            showTotalEntries={false}
            noEndBorder
            checkBoxes={false}
          />
        ) : (
          <MDTypography
            component="h2"
            href="#"
            color="text"
            fontWeight="bold"
            sx={{
              textAlign: "center",
              my: 10,
            }}
          >
            No Resume Found
          </MDTypography>
        )}
        <div style={{ visibility: "hidden", marginTop: "0rem" }}>
          <CvDesign tempRef={tempRef} />
        </div>
      </MDBox>
      <ResumeFeedbackModal
        modal={isOpen}
        handleClose={handleToggleModal}
        storeResumeFeedback={storeResumeFeedback}
      />
    </>
  );
};

export default UserResume;
