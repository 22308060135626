import { Box, Card, Divider, Grid, IconButton } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { useEffect, useMemo, useRef, useState } from "react";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DeleteIcon from "@mui/icons-material/Delete";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import { useDispatch, useSelector } from "react-redux";
import { updateColor } from "store/Slices/themeSlice";
import { updateOrgTheme } from "store/Slices/themeSlice";
import { updateThemeLocal } from "store/Slices/themeSlice";
import Loader from "components/Loader";
import { toast } from "react-toastify";
import { SketchPicker, SliderPicker } from "react-color";
import MDInput from "components/MDInput";
import { useDebounce } from "use-debounce";
import { createQuote } from "store/Slices/themeSlice";
import { getQuote } from "store/Slices/themeSlice";

const ProfileConfigration = ({ storePermissions }) => {
  const dispatch = useDispatch();
  const [controller] = useMaterialUIController();
  const { sidenavColor, darkMode } = controller;
  const { loading, orgTheme } = useSelector((state) => state.oraganizationTheme);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [sidenavType, setSidenavType] = useState(orgTheme.sidebar_type);
  const [sidenavbarColor, setSidenavbarColor] = useState(orgTheme.sidebar_color);
  const [displayColorPickerType, setDisplayColorPickerType] = useState(false);
  const [displayColorPickerColor, setDisplayColorPickerColor] = useState(false);
  const [dayOfTheMessage, setDayOfTheMessage] = useState("");
  const [debouncedSearch] = useDebounce(dayOfTheMessage, 1000);
  const isInitialMount = useRef(true);

  const memoizedPayload = useMemo(
    () => ({ quote: debouncedSearch, onSuccess: () => {} }),
    [debouncedSearch]
  );

  const handleClickType = () => {
    setDisplayColorPickerType(!displayColorPickerType);
  };

  const handleCloseType = () => {
    setDisplayColorPickerType(false);
  };

  const handleClickColor = () => {
    setDisplayColorPickerColor(!displayColorPickerColor);
  };

  const handleCloseColor = () => {
    setDisplayColorPickerColor(false);
  };

  const handleSidenavColor = (color) => {
    console.log(color, "colorbyahsan");
    setSelectedType(color);
    dispatch(updateThemeLocal(color));
  };

  const handleDisabled = (val) => {
    if (storePermissions) {
      if (storePermissions.hasOwnProperty(val)) {
        return !storePermissions[val];
      }
      return null;
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
      if (!allowedTypes.includes(file.type)) {
        toast.error(
          "Only PNG, JPG, and JPEG image files are allowed. Please upload a valid image format."
        );
        return;
      }

      setSelectedImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDeleteImage = () => {
    setImagePreview(null);
  };

  const handleSaveSettings = () => {
    const payload = {
      sidebar_color: selectedColor,
      sidebar_type: selectedType,
      icon: selectedImage,
    };
    dispatch(updateOrgTheme(payload))
      .then((res) => {})
      .catch((rejected) => toast.error(rejected?.response?.data?.detail));
  };

  const handleSidenavType = (color) => {
    setSidenavType(color.hex);
    handleSidenavColor(color.hex);
    console.log(color, "Selected color");
  };
  const handleSidenaBarColor = (color) => {
    setSidenavbarColor(color.hex);
    dispatch(updateColor(color.hex));
    setSelectedColor(color.hex);
    // handleSidenavColor(color.hex);
    console.log(color, "Selected color");
  };

  useEffect(() => {
    setSidenavType(orgTheme.sidebar_type);
    setSidenavbarColor(orgTheme.sidebar_color);
  }, [orgTheme]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else if (debouncedSearch) {
      dispatch(createQuote(memoizedPayload));
    }
  }, [memoizedPayload]);

  useEffect(() => {
    dispatch(
      getQuote({
        onSuccess: (res) => {
          setDayOfTheMessage(res?.quote);
        },
      })
    );
  }, []);

  return (
    <Card
      sx={{
        position: "relative",
        mt: 4,
        mx: 1,
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px;",
      }}
    >
      {loading && <Loader />}
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={4}
        pb={0.5}
        px={3}
      >
        <MDBox>
          <MDTypography variant="h5">DASHBOARD CONFIGRATION</MDTypography>
        </MDBox>
      </MDBox>

      <Divider />
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        {!imagePreview && (
          <>
            <input
              accept="image/*"
              style={{ display: "none" }}
              id="image-upload-input"
              type="file"
              onChange={handleImageChange}
              disabled={handleDisabled("update")}
            />
            <label htmlFor="image-upload-input">
              <Box
                sx={{
                  border: "2px dashed #ccc",
                  borderRadius: "8px",
                  width: "300px",
                  height: "100px",
                  display: "flex",
                  marginBottom: "10px",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  "&:hover": {
                    borderColor: `${handleDisabled("update") ? "" : "#aaa"}`,
                  },
                }}
              >
                <UploadFileIcon
                  sx={{ fontSize: 20, width: "50px", height: "50px", color: "#666" }}
                />
                <Box sx={{ fontSize: 14, color: "#666" }} mt={1}>
                  Upload Profile Image
                </Box>
              </Box>
            </label>
          </>
        )}

        {imagePreview && (
          <Box sx={{ my: 2, position: "relative", display: "inline-block" }}>
            <img
              src={imagePreview}
              alt="Selected"
              style={{
                width: "150px",
                height: "150px",
                borderRadius: "50%",
                display: "block",
              }}
            />
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                borderRadius: "50%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                opacity: 0,
                color: "red",
                transition: "opacity 0.3s",
                "&:hover": {
                  opacity: 1,
                },
              }}
            >
              <IconButton
                color="inherit"
                onClick={handleDeleteImage}
                disabled={handleDisabled("update")}
              >
                <DeleteIcon sx={{ fontSize: 20, width: "30px", height: "30px", color: "danger" }} />
              </IconButton>
            </Box>
          </Box>
        )}
      </Box>

      <MDBox pt={4} pb={0} px={3}>
        <MDBox>
          <MDTypography variant="h5" sx={{ mb: 2 }}>
            Message of the Day
          </MDTypography>
        </MDBox>
        <Grid item xs={12}>
          <MDBox mb={2}>
            <MDInput
              label="Add your Message"
              name="dayOfTheMessage"
              multiline
              rows={1}
              value={dayOfTheMessage}
              onChange={(e) => setDayOfTheMessage(e.target.value)}
              fullWidth
            />
          </MDBox>
        </Grid>
      </MDBox>

      <MDBox pt={0} pb={3} px={3}>
        <Grid
          container
          spacing={3}
          direction="row"
          sx={{
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        >
          <Grid item xs={12} md={5}>
            <MDBox>
              <MDTypography variant="h6" sx={{ mb: 3 }}>
                Sidebar Colors
              </MDTypography>

              <Box
                sx={{
                  borderRadius: "1px",
                  boxShadow: "0 0 0 1px rgba(0,0,0,0.1)",
                  display: "inline-block",
                  cursor: "pointer",
                }}
                onClick={handleClickColor}
              >
                <Box
                  sx={{
                    width: "300px",
                    height: "40px",
                    borderRadius: "2px",
                    backgroundColor: sidenavbarColor,
                  }}
                />
              </Box>
              {displayColorPickerColor && (
                <Box sx={{ position: "absolute", top: 0, zIndex: 2 }}>
                  <Box
                    sx={{
                      position: "fixed",
                      top: 0,
                      right: 0,
                      bottom: 0,
                      left: 0,
                    }}
                    onClick={handleCloseColor}
                  />
                  <SketchPicker color={sidenavbarColor} onChange={handleSidenaBarColor} />
                </Box>
              )}

              {/* <SliderPicker
                onChange={handleSidenaBarColor}
                color={sidenavbarColor}
                width="100px"
                triangle="top"
              /> */}
            </MDBox>
          </Grid>
          <Grid item xs={12} md={2}></Grid>
          <Grid item xs={12} md={5}>
            <MDBox lineHeight={1}>
              <MDTypography variant="h6" sx={{ mb: 3 }}>
                Sidebar Type
              </MDTypography>

              {/* <SliderPicker
                onChange={handleSidenavType}
                color={sidenavType}
                width="100px"
                triangle="top"
              /> */}

              <Box
                sx={{
                  borderRadius: "1px",
                  boxShadow: "0 0 0 1px rgba(0,0,0,0.1)",
                  display: "inline-block",
                  cursor: "pointer",
                }}
                onClick={handleClickType}
              >
                <Box
                  sx={{
                    width: "300px",
                    height: "40px",
                    borderRadius: "2px",
                    backgroundColor: sidenavType,
                  }}
                />
              </Box>
              {displayColorPickerType && (
                <Box sx={{ position: "absolute", top: 0, zIndex: 2 }}>
                  <Box
                    sx={{
                      position: "fixed",
                      top: 0,
                      right: 0,
                      bottom: 0,
                      left: 0,
                    }}
                    onClick={handleCloseType}
                  />
                  <SketchPicker color={sidenavType} onChange={handleSidenavType} />
                </Box>
              )}
            </MDBox>
          </Grid>
        </Grid>

        <Divider my={10} />
        <MDBox
          sx={{
            width: "100%",
            minWidth: "8rem",
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            bottom: 15,
            left: 0,
          }}
        >
          <MDButton
            color="dark"
            variant="gradient"
            onClick={handleSaveSettings}
            disabled={handleDisabled("update")}
            fullWidth
            sx={{
              width: "20%",
            }}
          >
            Save Settings
          </MDButton>
        </MDBox>
        <Divider />
      </MDBox>
    </Card>
  );
};

export default ProfileConfigration;
