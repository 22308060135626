import React, { useState } from "react";
import blueTick from "../../assets/images/blueTick.svg";
import { useDispatch, useSelector } from "react-redux";
import { checkOutSession } from "store/Slices/subscriptionSlice";
import { toast } from "react-toastify";
import { getSubscription } from "store/Slices/authSlice";
import { MonthlydummySubscriptionData } from "./DummyData";
import DownGradeModal from "examples/Modal/DownGradeModal";
import UpdateSubscritionModal from "examples/Modal/UpdateSubscritionModal";
import CustomPlanModal from "./CustomPlanModal";

const PricingCards = ({
  plan,
  handleDisabled,
  userStats,
  isCustomModalOpen,
  setIsCustomModalOpen,
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [isDownGradeModalOpen, setIsDownGradeModalOpen] = useState(false);
  const [activePricingId, setActivePricingId] = useState(null);
  const [activeCustumPricingId, setActiveCustumPricingId] = useState(null);
  const [buttonStatusLoader, setbuttonStatusLoader] = useState(false);
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);

  const activeBundleData = MonthlydummySubscriptionData.filter(
    (data) => data?.id === user?.activeBundle
  );

  const handleDownGradeModal = () => {
    setIsDownGradeModalOpen((prev) => !prev);
  };

  const getCurrentSubscription = () => {
    setbuttonStatusLoader(true);
    dispatch(getSubscription())
      .unwrap()
      .then((res) => {
        setbuttonStatusLoader(false);
      })
      .catch((rejected) => {
        toast.error(rejected?.response?.data?.detail);
        setbuttonStatusLoader(false);
      });
  };

  const handlePlanActivate = (pricingId, custumId) => {
    console.log(custumId, "custumId");
    setActivePricingId(pricingId);
    console.log(pricingId, "pricingId");

    if (pricingId && custumId && !user?.is_trial_consumed) {
      const payload = {
        bundlePlanId: pricingId,
      };
      dispatch(checkOutSession(payload))
        .unwrap()
        .then((res) => {
          toast.success(res.message);
          if (!res.checkout_url) {
            getCurrentSubscription();
          } else {
            setTimeout(() => {
              window.location.href = res.checkout_url;
            }, 1500);
          }
        });
      return;
    }

    if (pricingId && custumId && user?.is_trial_consumed) {
      setIsUpgradeModalOpen(true);
      return;
    }
    if (pricingId === "QolEraPnrw") {
      handleDownGradeModal();
    } else if (
      pricingId === "0qXPowPlVW" &&
      user?.activeBundle === "QolEraPnrw" &&
      user?.is_trial_consumed
    ) {
      setIsUpgradeModalOpen(true);
    } else if (pricingId === "0qXPowPlVW" && user?.activeBundle !== "QolEraPnrw") {
      // handleDownGradeModal();
      setIsUpgradeModalOpen(true);
    } else {
      const payload = {
        bundlePlanId: pricingId,
      };
      dispatch(checkOutSession(payload))
        .unwrap()
        .then((res) => {
          toast.success(res.message);
          if (!res.checkout_url) {
            getCurrentSubscription();
          } else {
            setTimeout(() => {
              window.location.href = res.checkout_url;
            }, 1500);
          }
        });
    }
  };

  return (
    <div className="pricing-plan-card">
      <div className="top-section">
        <h2>{plan.name}</h2>
        <p>{plan.title}</p>
      </div>
      <div className="description-section">
        <div className="price">
          {plan.price !== "Custom Pricing" && <p className="dollar">$</p>}
          {plan.price !== "Custom Pricing" ? <h2>{plan.price}</h2> : <h4>{plan.price}</h4>}
        </div>
        <div className="div-box">User/month</div>
        <div className="pricing-content">
          {plan.features.map((item, i) => (
            <div key={i} className="item">
              <div className="blue-tick-icon-wrapper">
                <img src={blueTick} alt="icon" />
              </div>
              <p>{item}</p>
            </div>
          ))}

          <div className="pricing-button">
            <>
              {plan?.idealFor && (
                <div className="mt-5 text-center">
                  <p className="idealFor">{plan.idealFor}</p>
                </div>
              )}
              <button
                className={`ic-btn-go-to-pro ic-btn-primary ${
                  handleDisabled("update") || user?.activeBundle == plan?.id || user?.cancelation
                    ? ""
                    : "button_cursor"
                }`}
                disabled={
                  handleDisabled("update") || user?.activeBundle == plan?.id || user?.cancelation
                }
                onClick={
                  plan?.id !== "enterprise"
                    ? () => handlePlanActivate(plan.id, null)
                    : () => setIsCustomModalOpen(true)
                }
              >
                {buttonStatusLoader
                  ? "Loading..."
                  : plan?.id == "enterprise" &&
                    user?.activeBundle !== "0qXPowPlVW" &&
                    user?.activeBundle !== "QolEraPnrw"
                  ? "Subscribed"
                  : plan?.id == "enterprise"
                  ? "Select Custom Plan"
                  : user?.activeBundle == plan?.id
                  ? "Subscribed"
                  : plan?.price > activeBundleData[0]?.price
                  ? "Upgrade Plan"
                  : "Downgrade Plan"}
              </button>
            </>
          </div>
        </div>
      </div>
      <DownGradeModal
        modal={isDownGradeModalOpen}
        handleModal={handleDownGradeModal}
        activePricingId={activePricingId}
        userStats={userStats}
      />
      <UpdateSubscritionModal
        openModal={isUpgradeModalOpen}
        setopenModal={setIsUpgradeModalOpen}
        activePricingId={activePricingId}
        setbuttonStatusLoader={setbuttonStatusLoader}
      />
      <CustomPlanModal
        isOpen={isCustomModalOpen}
        onClose={() => setIsCustomModalOpen(false)}
        activeCustumPricingId={activeCustumPricingId}
        setActiveCustumPricingId={setActiveCustumPricingId}
        onSubmit={(id, custumId) => handlePlanActivate(id, custumId)}
      />
    </div>
  );
};

export default PricingCards;
