import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useEffect, useState } from "react";
import "../../assets/css/chat.css";
import { ChatBody } from "./ChatBody";
import ChatFooter from "./ChatFooter";
import ChatHeader from "./ChatHeader";
import { ConservationList } from "./Conservations";
import { activeChatDetail } from "./utils";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { startChatAction } from "store/Slices/ChatSlice";
import { useDispatch, useSelector } from "react-redux";
import { getJoinedUsers } from "store/Slices/usersSlice";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { getChat } from "store/Slices/ChatSlice";
import { getUserId } from "store/Slices/ChatSlice";
import { convert } from "html-to-text";
import { ShimmerCategoryList } from "react-shimmer-effects";
import { socket } from "config/socketConfig";
import { updateChatMessages } from "store/Slices/ChatSlice";
import { updateConversations } from "store/Slices/ChatSlice";
import { pageRefresh } from "config/socketConfig";
import { setIn } from "formik";
import { updateActiveConversation } from "store/Slices/ChatSlice";
import { getChatMessages } from "store/Slices/ChatSlice";
import { modifyConversations } from "store/Slices/ChatSlice";
import logo from "../../assets/images/logos/logobanner.png";
import { toast } from "react-toastify";
const styleModal = {
  width: "90vw", // use a percentage width for responsiveness
  maxWidth: "500px", // set a max width to prevent it from getting too large
  margin: "auto", // center the modal
  padding: "20px", // add some padding
  boxShadow: 24,
  borderRadius: "8px",
};
const CustomerSupport = () => {
  // hooks
  const dispatch = useDispatch();
  const { joinedUsers } = useSelector((state) => state.userManagment);
  const { user } = useSelector((state) => state.auth);
  const { connectedChats, activeConversation, chatMessages, userId } = useSelector(
    (state) => state.chats
  );
  // states
  const [activeChatId, setActiveChatId] = useState(24);
  const [chatModal, setChatModal] = useState(false);
  const [groupChat, setGroupChat] = useState(false);
  const [groupProfileImage, setGroupProfileImage] = useState(null);
  const [groupChatName, setGroupChatName] = useState("");
  const [chatUser, setChatUser] = useState([]);
  const [conservationLoading, setConversationLoading] = useState(false);
  const [messagesLoading, setMessagesLoading] = useState(false);
  const [initializeLoader, setInitializeLoader] = useState(false);
  // functions
  const clearModal = () => {
    setGroupChat(false);
    setGroupChatName("");
    setChatUser([]);
    setChatModal(false);
    setGroupProfileImage(null);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setInitializeLoader(true);
    let formData = new FormData();
    formData.append("name", groupChatName);
    formData.append("is_group", groupChat);
    formData.append("member_type", "customer");
    if (groupProfileImage) {
      formData.append("image_file", groupProfileImage);
    }
    const memberIds = groupChat ? chatUser.join(",") : chatUser[0];
    formData.append("member_ids_list", memberIds);
    dispatch(
      startChatAction({
        payload: formData,
        onSuccess: (response) => {
          if (response?.status === true) {
            setInitializeLoader(false);
            setChatModal(false);
            if (response?.data?.id == activeConversation?.id) return;
            const updatedUnreadMessages = response?.data?.unread_messages?.map((message) =>
              message?.user_id === userId?.user_id ? { ...message, unread_messages: 0 } : message
            );
            let activeConversations = {
              ...response?.data,
              unread_messages: updatedUnreadMessages,
              active: true,
            };
            dispatch(updateActiveConversation(activeConversations));
            dispatch(
              getChatMessages({
                id: response?.data?.id,
                onSuccess: () => setMessagesLoading(false),
              })
            );
            dispatch(modifyConversations(response?.data));
          } else {
            setInitializeLoader(false);
          }
        },
      })
    );
  };

  const handleBackClick = () => {
    setActiveChatId(null);
  };
  //UseEffect
  useEffect(() => {
    if (user && userId) {
      pageRefresh({
        token: localStorage.getItem("auth"),
        encrypted_user_id: userId?.user_id,
      });
    }
  }, [userId]);
  useEffect(() => {
    if (user && userId) {
      setConversationLoading(true);
      dispatch(updateActiveConversation(null));
      dispatch(getUserId());
      dispatch(getChat(() => setConversationLoading(false)));
      // socket.on("get_message", async (msg) => {
      //   console.log("msg1: ", msg);
      //   dispatch(updateChatMessages(msg?.data));
      //   dispatch(updateConversations(msg?.data?.updatedConversation));
      // });
    }
  }, []);
  return (
    <DashboardLayout>
      <MDBox
        mx={2}
        mt={2}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
        display="flex"
        justifyContent="space-between"
      >
        <MDTypography variant="h6" color="white">
          Chats
        </MDTypography>
        <Box>
          <Button
            variant="contained"
            sx={{
              color: "#fff",
              bgcolor: "#000",
              "&:hover": {
                bgcolor: "darkslategray",
              },
            }}
            onClick={() => {
              clearModal();
              setChatModal(true);
              dispatch(getJoinedUsers());
            }}
          >
            Create New Chat
          </Button>
        </Box>
      </MDBox>
      <Container sx={{ mt: 2.5 }}>
        <Box className="d-flex w-100 ">
          <Box
            sx={{
              display: { xs: activeConversation ? "none" : "block", lg: "block" },
              width: { xs: "100%", lg: "30%" },
            }}
          >
            <ConservationList
              conservationLoading={conservationLoading}
              conservations={connectedChats}
              setMessagesLoading={setMessagesLoading}
            />
          </Box>

          <Box
            sx={{
              display: { xs: activeConversation ? "block" : "none", lg: "block" },
            }}
            className="w-100 overflow-hidden"
          >
            {activeConversation !== null ? (
              <>
                <ChatHeader />

                <ChatBody chat={activeConversation ? chatMessages : []} />
                <ChatFooter />
              </>
            ) : (
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "60%",
                  transform: "translate(-50%, -50%)",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Box
                  sx={{ mb: 2, display: "flex", justifyContent: "center", alignItems: "center" }}
                >
                  <img src={logo} alt="logo" />{" "}
                </Box>
                <Typography variant="h5" fontWeight="bold" color="text.primary">
                  No Chat Selected
                </Typography>
                <span style={{ color: "gray", fontSize: "17px" }}>
                  Please select a chat to start messaging.
                </span>
              </Box>
            )}
          </Box>
        </Box>
      </Container>
      <Modal
        open={chatModal}
        onClose={() => setChatModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid container justifyContent="center" alignItems="center" style={{ minHeight: "100vh" }}>
          <Card sx={styleModal}>
            <Box
              variant="gradient"
              bgcolor="info.main"
              borderRadius="12px"
              boxShadow={3}
              mx={2}
              p={2}
              mb={2}
              textAlign="center"
            >
              <MDTypography variant="h4" fontWeight="medium" color="white" mb={1}>
                Start Chat
              </MDTypography>
            </Box>
            <MDBox mx={1} p={1} mt={1}>
              <form onSubmit={handleSubmit}>
                <MDBox mb={2}>
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={groupChat}
                          onChange={() => {
                            setGroupChat(!groupChat);
                            setGroupProfileImage(null);
                            setGroupChatName("");
                          }}
                        />
                      }
                      label="Group Chat"
                    />
                  </Box>
                </MDBox>
                {groupChat && (
                  <>
                    <MDBox mb={2}>
                      <MDInput
                        type="text"
                        label="Group Chat Name"
                        name="Group Chat Name"
                        value={groupChatName}
                        required
                        onChange={(e) => setGroupChatName(e.target.value)}
                        fullWidth
                      />
                    </MDBox>
                    <MDBox mb={2}>
                      <input
                        type="file"
                        name="Group Chat Image"
                        required
                        accept=".jpg, .jpeg, .png"
                        onChange={(e) => {
                          const file = e.target.files[0];
                          if (file) {
                            const fileSizeInMB = file.size / 1024 / 1024; // Convert bytes to MB
                            if (fileSizeInMB > 1) {
                              toast.error("File size must be less than 1 MB");
                              e.target.value = null; // Reset the input if the file is too large
                            } else {
                              setGroupProfileImage(file);
                            }
                          }
                        }}
                        fullWidth
                      />
                    </MDBox>
                  </>
                )}
                <MDBox mb={2}>
                  <Box>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label-users">Users</InputLabel>
                      <Select
                        labelId="demo-simple-select-label-users"
                        id="demo-simple-select"
                        name="account"
                        multiple={groupChat} // Conditionally allow multiple selection based on groupChat
                        value={chatUser}
                        onChange={(event) => {
                          const {
                            target: { value },
                          } = event;

                          if (groupChat) {
                            // When groupChat is true, allow multiple selection
                            setChatUser(typeof value === "string" ? value.split(",") : value);
                          } else {
                            // When groupChat is false, replace the previous selection
                            setChatUser([value]); // Ensure value is an array with a single selected option
                          }
                        }}
                        label="Users"
                        required
                      >
                        {joinedUsers?.map((user) => (
                          <MenuItem key={user?.customer_id} value={user?.customer_id}>
                            {user?.customer_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </MDBox>
                <MDBox mt={4} mb={1} sx={{ display: "flex", gap: "10px", justifyContent: "end" }}>
                  <MDButton variant="gradient" color="info" onClick={() => setChatModal(false)}>
                    Cancel
                  </MDButton>
                  <MDButton
                    variant="gradient"
                    color="info"
                    type="submit"
                    disabled={initializeLoader}
                  >
                    {initializeLoader ? <CircularProgress size={20} /> : "Start Chat"}
                  </MDButton>
                </MDBox>
              </form>
            </MDBox>
          </Card>
        </Grid>
      </Modal>
    </DashboardLayout>
  );
};

export default CustomerSupport;

const conservations = [
  {
    id: 24,
    name: "Next Pak Chat",
    image: "chat_groups/chat_38_customer/1727270625_img3.jpg",
    is_group: true,
    members: [
      {
        user_id: 24,
        name: "Next Pak Chat",
        image:
          "https://nexa-web-portal.s3.amazonaws.com/chat_groups/chat_38_customer/1727270625_img3.jpg?AWSAccessKeyId=AKIARKTJKDK4GZAMF5GM&Signature=xpBovE8YQZ%2ByXKgIvbpBXXhjVOM%3D&Expires=1727946801",
      },
    ],
    last_message: {
      id: 170,
      content: "hello bhai",
      media: null,
      extension: null,
      sender_name: "Aqib Siddique",
      created_at: "2024-09-26T07:55:08",
    },
    unread_messages: [
      {
        user_id: "VGyEmVD016",
        user_type: "customer",
        unread_messages: 3,
      },
      {
        user_id: "JakEeyE2j1",
        user_type: "organization",
        unread_messages: 2,
      },
    ],
  },
  {
    id: 23,
    name: null,
    image: null,
    is_group: false,
    members: [
      {
        user_id: 31,
        name: "Ntech",
        image:
          "https://nexa-web-portal.s3.amazonaws.com/theming/british.png?AWSAccessKeyId=AKIARKTJKDK4GZAMF5GM&Signature=KWbOBSIJ%2B%2F5whLxGNUS0pMl%2BA%2Bw%3D&Expires=1727946801",
      },
    ],
    last_message: {
      id: 169,
      content: "hi bhai",
      media: null,
      extension: null,
      sender_name: "Aqib Siddique",
      created_at: "2024-09-26T07:54:58",
    },
    unread_messages: [],
  },
];

const selectedUserChat = [
  {
    date: "25/09/2024",
    groupedMessages: [
      {
        id: 165,
        chat_id: 24,
        sender_id: 31,
        sender_name: "Ntech",
        sender_image:
          "https://nexa-web-portal.s3.amazonaws.com/theming/british.png?AWSAccessKeyId=AKIARKTJKDK4GZAMF5GM&Signature=100ypCKSxSLrPaJyISK%2FRQ%2FktfY%3D&Expires=1727946898",
        content: "testing",
        media: null,
        extension: null,
        is_my_message: false,
        message_type: null,
        unread_messages: null,
        created_at: "2024-09-26T07:24:17",
      },
      {
        id: 166,
        chat_id: 24,
        sender_id: 38,
        sender_name: "Aqib Siddique",
        sender_image:
          "https://nexa-web-portal.s3.amazonaws.com/profile/38_Screenshot%202023-02-28%20142551.png?AWSAccessKeyId=AKIARKTJKDK4GZAMF5GM&Signature=v%2Bf0JHvsl90E8HSQJu7AD%2BjfdTo%3D&Expires=1727946898",
        content: "Hello how are you!",
        media: null,
        extension: null,
        is_my_message: true,
        message_type: null,
        unread_messages: null,
        created_at: "2024-09-26T07:25:29",
      },
      {
        id: 170,
        chat_id: 24,
        sender_id: 38,
        sender_name: "Aqib Siddique",
        sender_image:
          "https://nexa-web-portal.s3.amazonaws.com/profile/38_Screenshot%202023-02-28%20142551.png?AWSAccessKeyId=AKIARKTJKDK4GZAMF5GM&Signature=v%2Bf0JHvsl90E8HSQJu7AD%2BjfdTo%3D&Expires=1727946898",
        content: "hello bhai",
        media: null,
        extension: null,
        is_my_message: true,
        message_type: null,
        unread_messages: null,
        created_at: "2024-09-26T03:55:08",
      },
      {
        id: 170,
        chat_id: 24,
        sender_id: 38,
        sender_name: "Aqib Siddique",
        sender_image:
          "https://nexa-web-portal.s3.amazonaws.com/profile/38_Screenshot%202023-02-28%20142551.png?AWSAccessKeyId=AKIARKTJKDK4GZAMF5GM&Signature=v%2Bf0JHvsl90E8HSQJu7AD%2BjfdTo%3D&Expires=1727946898",
        content: "hello bhai",
        media: null,
        extension: null,
        is_my_message: true,
        message_type: null,
        unread_messages: null,
        created_at: "2024-09-26T04:55:08",
      },
    ],
  },
];
