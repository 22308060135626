import { useState, useEffect } from "react";

import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

import MDBox from "components/MDBox";

import breakpoints from "assets/theme/base/breakpoints";

import backgroundImage from "assets/images/bg-profile.jpeg";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "store/Slices/profileSlice";
import { useLocation } from "react-router-dom";

function SettingHeader({ children, setParentActiveState }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);
  const { profile } = useSelector((state) => state.orgProfile);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);

    handleTabsOrientation();

    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => {
    setParentActiveState(newValue);
    setTabValue(newValue);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const queryParam = searchParams.get("query");
    if (queryParam === "configuration") {
      setTabValue(3);
      setParentActiveState(3);
    }
  }, []);

  return (
    <MDBox position="relative" mb={5}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.6),
              rgba(gradients.info.state, 0.6)
            )}, url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid
            item
            sx={{
              fontSize: "22px",
              fontWeight: "700",
              display: "flex",
              alignItems: "center",
            }}
          >
            {profile?.name}
          </Grid>
          <Grid item xs={12} md={6} lg={7} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                <Tab
                  label="Profile"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      group
                    </Icon>
                  }
                />
                {!user?.data && (
                  <Tab
                    label="Payments"
                    icon={
                      <Icon fontSize="small" sx={{ mt: -0.25 }}>
                        payments
                      </Icon>
                    }
                  />
                )}
                {!user?.data && (
                  <Tab
                    label="Billing History"
                    icon={
                      <Icon fontSize="small" sx={{ mt: -0.25 }}>
                        receipt_long
                      </Icon>
                    }
                  />
                )}
                <Tab
                  label="Configration"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      settings
                    </Icon>
                  }
                />
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
        {children}
      </Card>
    </MDBox>
  );
}

SettingHeader.defaultProps = {
  children: "",
};

SettingHeader.propTypes = {
  children: PropTypes.node,
};

export default SettingHeader;
