import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import { toast } from "react-toastify";

const CustomPlanModal = ({
  isOpen,
  onClose,
  activeCustumPricingId,
  setActiveCustumPricingId,
  onSubmit,
}) => {
  const handleCustomPlanSubmit = () => {
    if (activeCustumPricingId.trim()) {
      onSubmit(activeCustumPricingId, true);
      onClose();
      setActiveCustumPricingId("");
    } else {
      toast.error("Please enter a valid plan code.");
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Enter Custom Plan Code</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Plan Code"
          type="text"
          fullWidth
          variant="outlined"
          value={activeCustumPricingId}
          onChange={(e) => setActiveCustumPricingId(e.target.value)}
          placeholder="Enter your plan code"
          sx={{
            width: "400px",
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setActiveCustumPricingId("");
            onClose();
          }}
          color="secondary"
        >
          Cancel
        </Button>
        <Button onClick={handleCustomPlanSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomPlanModal;
