import { Box, Typography } from "@mui/material";
import { getStickyDate } from "../utils";

const StickyDate = ({ date }) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      position: "sticky",
      top: 0,
      opacity: 0.8,
      zIndex: 1,
    }}
  >
    <Typography
      sx={{
        background: "#333",
        borderRadius: 2,
        px: 1,
        py: 0.2,
        color: "#fff",
        fontSize: "14px",
        mb: 1,
      }}
      component="p"
    >
      {getStickyDate(date)}
    </Typography>
  </Box>
);

export default StickyDate;
