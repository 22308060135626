import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";

dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.extend(customParseFormat);

export function getStickyDate(inputDate) {
  const date = dayjs(inputDate, "DD/MM/YYYY");

  if (date.isValid()) {
    if (date.isToday()) {
      return "today";
    } else if (date.isYesterday()) {
      return "yesterday";
    } else {
      return date.format("MM/DD/YYYY");
    }
  } else {
    return "Invalid date";
  }
}

// get unread messages from User
export const getUnreadMessage = (unread_messages_array, myUserId) => {
  if (!unread_messages_array) {
    return 0;
  }
  if (unread_messages_array.length === 0) {
    return 0;
  }

  const unreadMessageCount = unread_messages_array?.find(
    (message) => message?.user_id !== myUserId
  )?.unread_messages;

  return unreadMessageCount;
};

// get active Chat Detail
export const activeChatDetail = (conservations, activeChatId) => {
  if (!activeChatId) {
    return {
      image: "",
      name: "Not Selected",
    };
  }
  return conservations?.find((conservation) => conservation?.id === activeChatId)?.members[0];
};
