import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import UndoIcon from "@mui/icons-material/Undo";
import breakpoints from "assets/theme/base/breakpoints";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

function UserDetailHeader({ children, setParentActiveState }) {
  const navigate = useNavigate();
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);

    handleTabsOrientation();

    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => {
    setParentActiveState(newValue);
    setTabValue(newValue);
  };

  return (
    <MDBox position="relative" mb={5}>
      <Card
        sx={{
          position: "relative",
          mt: 8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid
            item
            sx={{
              fontSize: "22px",
              fontWeight: "700",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              size="small"
              sx={{
                color: "#fff",
                bgcolor: "info",
                "&:hover": {
                  bgcolor: "darkslategray",
                },
              }}
              onClick={() => navigate(-1)}
            >
              <UndoIcon fontSize="large" sx={{ mt: -0.25, mr: 0.5 }} />
              Back
            </Button>
          </Grid>
          <Grid item xs={12} md={10} lg={9} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                <Tab
                  label="Profile Information"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      group
                    </Icon>
                  }
                />
                <Tab
                  label="Stats"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      payments
                    </Icon>
                  }
                />
                <Tab
                  label="Practice Rounds"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      assessment
                    </Icon>
                  }
                />
                <Tab
                  label="Interview Simulations"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      quiz
                    </Icon>
                  }
                />
                <Tab
                  label="Resume"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      receipt_long
                    </Icon>
                  }
                />
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
        {children}
      </Card>
    </MDBox>
  );
}

UserDetailHeader.defaultProps = {
  children: "",
};

UserDetailHeader.propTypes = {
  children: PropTypes.node,
};

export default UserDetailHeader;
