import Repository from "./Repository";
const INVITE_NEW_SUB_ORG = "/sub_organization/invite";
const GET_ALL_INVITATIONS = "/sub_organization/list";
const REVOKE_SUB_ORG = "/sub_organization/revoke";
const ACTIVATE_REVOKED = "/sub_organization/active_revoked_invitation";
const RESEND_INVITATION = "/sub_organization/resend_invitation";
const DELETE_INVITATION = "/sub_organization/invite/delete";
const REGISTER_SUB_ORG = "/sub_organization/register";
const GET_SUBORGANIZATION_USER = "organization/invitations/joined";

const subOrgRepository = {
  invite(payload) {
    return Repository.post(INVITE_NEW_SUB_ORG, payload);
  },
  getAll(payload) {
    let { size, page } = payload;
    return Repository.get(`${GET_ALL_INVITATIONS}?size=${size}&page=${page}`);
  },
  revoke(payload) {
    return Repository.put(`${REVOKE_SUB_ORG}`, payload);
  },
  activateRevoked(payload) {
    return Repository.put(`${ACTIVATE_REVOKED}`, payload);
  },
  resendInvitation(payload) {
    return Repository.put(`${RESEND_INVITATION}`, payload);
  },
  delete(payload) {
    return Repository.post(`${DELETE_INVITATION}`, payload);
  },
  register(payload) {
    return Repository.post(`${REGISTER_SUB_ORG}`, payload);
  },
  getsubOrgUser(payload) {
    return Repository.get(`${GET_SUBORGANIZATION_USER}?id=${payload}`);
  },
};

export default subOrgRepository;
