import Icon from "@mui/material/Icon";
import Admins from "layouts/Admins";
import InviteAdmin from "layouts/authentication/InviteSubAdmin";
import ResetPassword from "layouts/authentication/reset-password";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import Chat from "layouts/Chat";
import Dashboard from "layouts/dashboard";
import Introductions from "layouts/Introductions/index";
import NotAuthenticated from "layouts/NotAuthenticated";
import OnBording from "layouts/onboarding";
import CookiesPolicy from "layouts/OpenPages/CookiesPolicy";
import PrivacyPolicy from "layouts/OpenPages/PrivacyPolicy";
import TOS from "layouts/OpenPages/TOS";
import RCQSection from "layouts/RCQSection";
import ReportsAnalytics from "layouts/ReportsAnalytics";
import ViewReports from "layouts/ReportsAnalytics/Components/ViewReports";
import Setting from "layouts/setting";
import SkillsGapDetail from "layouts/SkillsGapDetail";
import SubOrganizations from "layouts/subOrganizations";
import InviteSubOrganization from "layouts/subOrganizations/invite";
import SubOrganizationsDetail from "layouts/SubOrganizationsDetail";
import Subscriptions from "layouts/Subscriptions";
import Tasks from "layouts/Tasks";
import CreateTask from "layouts/Tasks/CreateTask";
import TaskDetail from "layouts/Tasks/TaskDetail";
import UserAnalytics from "layouts/UserAnalytics";
import Users from "layouts/users";
import SkillsGap from "./layouts/SkillsGap";

const routes = [
  {
    type: "collapse",
    name: "User Details",
    key: "user-details",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/user-details/:id",
    component: <UserAnalytics />,
    isMenu: false,
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Introduction",
    key: "introduction",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/introduction",
    component: <Introductions />,
    isMenu: false,
  },
  {
    type: "collapse",
    name: "Reporting & Analytics",
    key: "reporting-&-analytics",
    icon: <Icon fontSize="small">reports</Icon>,
    route: "/reporting-&-analytics",
    component: <ReportsAnalytics />,
  },
  {
    type: "collapse",
    name: "View Report",
    key: "view-report",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/view-report/:id",
    component: <ViewReports />,
    isMenu: false,
  },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    icon: <Icon fontSize="small">persons</Icon>,
    route: "/users",
    component: <Users />,
  },
  {
    type: "collapse",
    name: "Tasks",
    key: "Tasks",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/Tasks",
    component: <Tasks />,
  },
  {
    type: "collapse",
    name: "Tasks-Details",
    key: "Tasks-Details",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/viewTask/:id",
    component: <TaskDetail />,
    isMenu: false,
  },
  {
    type: "collapse",
    name: "Chats",
    key: "chats",
    icon: <Icon fontSize="small">chat</Icon>,
    route: "/chats",
    component: <Chat />,
  },
  // {
  //   type: "collapse",
  //   name: "Roles",
  //   key: "roles",
  //   icon: <Icon fontSize="small">admin_panel_settings</Icon>,
  //   route: "/roles",
  //   component: <Roles />,
  // },
  {
    type: "collapse",
    name: "Create Your Practice Round",
    key: "create_practice_round",
    icon: <Icon fontSize="small">admin_panel_settings</Icon>,
    route: "/create_practice_round",
    component: <RCQSection />,
  },
  // {
  //   type: "collapse",
  //   name: "Roles Categories",
  //   key: "roles-categories",
  //   icon: <Icon fontSize="small">category</Icon>,
  //   route: "/roles-categories",
  //   component: <RolesCategories />,
  // },

  // {
  //   type: "collapse",
  //   name: "Questions",
  //   key: "questions",
  //   icon: <Icon fontSize="small">question_mark</Icon>,
  //   route: "/questions",
  //   component: <Questions />,
  // },

  {
    type: "collapse",
    name: "tos",
    key: "tos",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/open/tos",
    component: <TOS />,
    isMenu: false,
  },
  {
    type: "collapse",
    name: "privacy-policy",
    key: "privacy-policy",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/open/privacy-policy",
    component: <PrivacyPolicy />,
    isMenu: false,
  },
  {
    type: "collapse",
    name: "cookies-policy",
    key: "cookies-policy",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/open/cookies-policy",
    component: <CookiesPolicy />,
    isMenu: false,
  },

  // {
  //   type: "collapse",
  //   name: "Profile",
  //   key: "profile",
  //   icon: <Icon fontSize="small">group</Icon>,
  //   route: "/profile",
  //   component: <Profile />,
  // },
  {
    type: "collapse",
    name: "Admins",
    key: "admins",
    icon: <Icon fontSize="small">manager</Icon>,
    route: "/admins",
    component: <Admins />,
  },
  {
    type: "collapse",
    name: "Not Authenticated",
    key: "not-authenticated",
    icon: <Icon fontSize="small">manager</Icon>,
    route: "/not-authenticated",
    component: <NotAuthenticated />,
    isMenu: false,
  },
  {
    type: "collapse",
    name: "Sub Organizations",
    key: "sub-organizations",
    icon: <Icon fontSize="small">corporate_fare</Icon>,
    route: "/sub-organizations",
    component: <SubOrganizations />,
  },
  {
    type: "collapse",
    name: "Sub Organizations Detail",
    key: "sub-organizations-detail",
    icon: <Icon fontSize="small">manager</Icon>,
    route: "/viewsuborganization/:id",
    component: <SubOrganizationsDetail />,
    isMenu: false,
  },
  {
    type: "collapse",
    name: "Sub Organization Invite",
    key: "sub-organization",
    icon: <Icon fontSize="small"></Icon>,
    route: "/authentication/sub-organization/invite",
    component: <InviteSubOrganization />,
    isMenu: false,
  },
  {
    type: "collapse",
    name: "Skills Gap Analysis",
    key: "skills-gap-analysis",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/skills-gap-analysis",
    component: <SkillsGap />,
  },
  {
    type: "collapse",
    name: "Skills Gap Analysis Detail",
    key: "skills-gap-analysis-details",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/skills-gap-analysis-details/:id",
    component: <SkillsGapDetail />,
    isMenu: false,
  },
  {
    type: "collapse",
    name: "Subscription Plan",
    key: "subscription",
    icon: <Icon fontSize="small">upcoming</Icon>,
    route: "/subscription",
    component: <Subscriptions />,
  },
  // {
  //   type: "collapse",
  //   name: "Analytics",
  //   key: "analytics",
  //   icon: <Icon fontSize="small">notifications</Icon>,
  //   route: "/analytics",
  //   component: <Analytics />,
  // },
  // {
  //   type: "collapse",
  //   name: "Payments",
  //   key: "payments",
  //   icon: <Icon fontSize="small">receipt_long</Icon>,
  //   route: "/payments",
  //   component: <Payments />,
  // },

  {
    type: "collapse",
    name: "Settings",
    key: "settings",
    icon: <Icon fontSize="small">settings</Icon>,
    route: "/settings",
    component: <Setting />,
  },
  // {
  //   type: "collapse",
  //   name: "Coming soon:",
  //   key: "comingsoon",
  //   icon: <Icon fontSize="small">upcoming</Icon>,
  //   route: "/comingsoon",
  // },
  {
    type: "collapse",
    name: "Sign Out",
    key: "signout",
    icon: <Icon fontSize="small">logout</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
    isMenu: false,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
    isMenu: false,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/reset-password/:id?",
    component: <ResetPassword />,
    isMenu: false,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/invite/:id?",
    component: <InviteAdmin />,
    isMenu: false,
  },
  {
    type: "collapse",
    name: "Forget Password",
    key: "forget-password",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/forget-password",
    component: <forgetPassword />,
    isMenu: false,
  },
  {
    type: "collapse",
    name: "On Bording",
    key: "onboarding",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/onboarding",
    component: <OnBording />,
    isMenu: false,
  },
  {
    type: "collapse",
    name: "Create Task",
    key: "createtask",
    route: "/createTask",
    component: <CreateTask />,
    isMenu: false,
  },
  {
    type: "collapse",
    name: "Create Task",
    key: "createtask",
    route: "/createTask/:id",
    component: <CreateTask />,
    isMenu: false,
  },
];

export default routes;
