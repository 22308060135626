import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { error } from "ajv/dist/vocabularies/applicator/dependencies";
import { toast } from "react-toastify";
import { RepositoryFactory } from "repository/RepositoryFactory";
const adminRepo = RepositoryFactory.get("admin");

export const post_invite_subAdmin = createAsyncThunk(
  "admin/invite_subAdmin",
  async (payload, { rejectWithValue }) => {
    // console.log(payload, "payload>>>>>>>>>>");
    try {
      const { data } = await adminRepo.post_Invite_Admin(payload);
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const get_subAdmins = createAsyncThunk(
  "admin/get_subAdmins",
  async ({ payload, onSuccess = () => {} }, { rejectWithValue }) => {
    try {
      const { data } = await adminRepo.get_All_Admins(payload);
      if (data.status_code === 200) {
        onSuccess();
        return data.data;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateSubAdmin = createAsyncThunk(
  "role/updateAdmin",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await adminRepo.updateSubAdmin(payload);
      if (data.status_code === 200) {
        return data?.detail;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const revokeInvitaion = createAsyncThunk(
  "admin/revokeInvitaion",
  async ({ email, onSuccess }, { rejectWithValue }) => {
    // console.log(email, "inside revoked invitation>>>>>>>>>");
    try {
      let payload = {
        email,
      };
      const { data } = await adminRepo.put_Revoked(payload);
      // console.log(data, "data returned>>>>>>>>>>>");
      onSuccess(data?.detail);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const ActiveInvitation = createAsyncThunk(
  "admin/activeInvitation",
  async ({ email, onSuccess }, { rejectWithValue }) => {
    // console.log(email, "insideactiveinvitation>>>>>>>>>>>");
    try {
      let payload = {
        email,
      };
      const { data } = await adminRepo.put_Active_Revoked(payload);
      // console.log(data, "data returned>>>>>>>>>>>");
      onSuccess(data?.detail);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const deleteRevokedAdmin = createAsyncThunk(
  "admin/deleteRevokedAdmin",
  async (payload, { rejectWithValue, dispatch }) => {
    console.log(payload, "payload");
    try {
      await adminRepo.deleteRevokedAdmin(payload.selectedAdminEmail);
      await dispatch(get_subAdmins({ payload: payload.pagination }));
      onSuccess();
      return;
    } catch (err) {
      toast.error(err?.response?.data?.detail);
      return rejectWithValue(err);
    }
  }
);

export const resendInvitation = createAsyncThunk(
  "admin/resendInvitation",
  async ({ email, onSuccess }, { rejectWithValue }) => {
    try {
      let payload = {
        email,
      };
      const { data } = await adminRepo.Resend_Invitation(payload);
      onSuccess(data?.detail);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState = {
  loading: false,
  subAdmins: [],
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //Get all roles
      .addCase(post_invite_subAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(post_invite_subAdmin.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(post_invite_subAdmin.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(get_subAdmins.pending, (state) => {
        state.loading = true;
      })
      .addCase(get_subAdmins.fulfilled, (state, action) => {
        state.loading = false;
        state.subAdmins = action.payload.invitations;
        let pagination = {
          current_page: action.payload?.current_page,
          limit: action.payload?.limit,
          total_pages: action.payload?.total_pages,
          total_invitations: action.payload?.total_invitations,
        };
        state.paginationData = pagination;
      })
      .addCase(get_subAdmins.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(revokeInvitaion.pending, (state) => {
        state.loading = true;
      })
      .addCase(revokeInvitaion.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(revokeInvitaion.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(ActiveInvitation.pending, (state) => {
        state.loading = true;
      })
      .addCase(ActiveInvitation.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(ActiveInvitation.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(resendInvitation.pending, (state) => {
        state.loading = true;
      })
      .addCase(resendInvitation.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(resendInvitation.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateSubAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateSubAdmin.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateSubAdmin.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteRevokedAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteRevokedAdmin.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteRevokedAdmin.rejected, (state, action) => {
        state.loading = false;
      });
  },
});
export default adminSlice.reducer;
