import {
  Avatar,
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  InputLabel,
  Modal,
  OutlinedInput,
  Tooltip,
  Typography,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import { SearchOutlined } from "@mui/icons-material";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import ConfirmationModal from "../../components/ConfirmationModal";
import { useDispatch, useSelector } from "react-redux";
import {
  addRole,
  deleteBulkRole,
  deleteRole,
  getAllRole,
  updateRole,
  updateVisibleRole,
} from "../../store/Slices/roleSlice";
import Loader from "components/Loader";
import { toast } from "react-toastify";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { getRouteByNumber } from "context";
import { useNavigate } from "react-router-dom";
import { featurePermissionsObject } from "context";
import { permission } from "context";
// import { display } from "html2canvas/dist/types/css/property-descriptors/display";
const styleModal = {
  width: "90vw", // use a percentage width for responsiveness
  maxWidth: "600px", // set a max width to prevent it from getting too large
  margin: "auto", // center the modal
  padding: "20px", // add some padding
  boxShadow: 24,
  borderRadius: "8px",
};
export default function index() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { loading, allRoles } = useSelector((state) => state.roles);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [filteredRoles, setFilteredRoles] = useState([]);
  const [search, setSearch] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
  const [roleName, setRoleName] = useState("");
  const [roleIcon, setRoleIcon] = useState("");
  const [selectedRoleId, setSelectedRoleId] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [withOutOrgIds, setwithOutOrgIds] = useState([]);
  const [storePermissions, setStorePermissions] = useState(null);
  const parsedObject = JSON.parse(user?.rolePermissions);
  let pagePermissions;
  let featurePermissions;
  if (parsedObject) {
    const [key, value] = Object.entries(parsedObject)[0];
    pagePermissions = key;
    featurePermissions = value;
  }

  const handleDisabled = (val) => {
    if (storePermissions) {
      if (storePermissions.hasOwnProperty(val)) {
        return !storePermissions[val];
      }
      return null;
    }
  };

  const handleConfirmationToggle = () => {
    setConfirmationModal((prevState) => !prevState);
  };
  const handleDelete = async () => {
    await dispatch(
      deleteRole({
        roleId: selectedRoleId,
        onSuccess: () => {
          setConfirmationModal(false);
          dispatch(getAllRole());
        },
      })
    );
  };
  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleDeleteConfirmationToggle = () => {
    setDeleteConfirmationModal((prevState) => !prevState);
  };
  const handleAllDelete = () => {
    const filteredCategories = allRoles
      .filter((category) => selectedRows.includes(category.id))
      .filter((category) => category.organization_id != null);
    const filteredIds = filteredCategories.map((category) => category.id);
    dispatch(
      deleteBulkRole({
        payload: filteredIds,
        onSuccess: () => {
          dispatch(getAllRole());
          setSelectedRows([]);
        },
      })
    );
    handleDeleteConfirmationToggle();
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setRoleIcon(file);
  };
  const handleClearFields = () => {
    setModalType("");
    setRoleName("");
    setRoleIcon("");
  };

  const handleToggleVisibility = (role, id) => {
    dispatch(
      updateVisibleRole({
        payload: {
          roleId: id,
          form: role,
        },
        onSuccess: () => {
          dispatch(getAllRole());
        },
      })
    );
  };

  const handleSubmit = () => {
    const roleExists = filteredRoles.some(
      (role) => role.name.toLowerCase() === roleName.toLowerCase()
    );
    if (modalType == "add") {
      const formData = new FormData();
      formData.append("iconFile", roleIcon);
      let payload = {
        name: roleName,
        form: formData,
      };
      if (roleExists) {
        toast.error("This role name already exists.");
      } else {
        dispatch(
          addRole({
            payload,
            onSuccess: () => {
              handleClearFields();
              dispatch(getAllRole());
            },
          })
        );
        setModalOpen(false);
      }
    } else {
      const formData = new FormData();
      if (typeof roleIcon != "string") {
        formData.append("file", roleIcon);
      }
      formData.append("name", roleName);
      let payload = {
        roleId: selectedRoleId,
        form: formData,
      };
      if (roleExists) {
        toast.error("This role name already exists.");
      } else {
        dispatch(
          updateRole({
            payload,
            onSuccess: () => {
              handleClearFields();
              dispatch(getAllRole());
            },
          })
        );
      }
    }
  };

  useEffect(() => {
    setColumns([
      { Header: "Name", accessor: "display_name", align: "left" },
      { Header: "Icon", accessor: "iconUrl", align: "left" },
      { Header: "Action", accessor: "action", align: "center" },
    ]);
    const customAddRoles =
      filteredRoles &&
      filteredRoles.length >= 1 &&
      filteredRoles?.map((data, index) => {
        return {
          id: data?.id,
          display_name: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {data?.display_name}
            </MDTypography>
          ),
          iconUrl: <Avatar src={data?.iconUrl} sx={{ width: 60, height: 60 }} />,
          action: (
            <>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  marginRight: "10px",
                }}
                size="small"
                disabled={handleDisabled("update")}
                onClick={() => {
                  const status = data?.status == "visible" ? "hidden" : "visible";
                  handleToggleVisibility(status, data?.id);
                }}
              >
                {data?.status == "visible" ? (
                  <Icon sx={{ color: "#fff", fontSize: "16px" }}>visibility</Icon>
                ) : (
                  <VisibilityOffIcon sx={{ color: "#fff", fontSize: "16px" }} />
                )}
              </Button>

              {data?.organization_id != null && (
                <>
                  {" "}
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      marginRight: "10px",
                    }}
                    size="small"
                    disabled={handleDisabled("update")}
                    onClick={() => {
                      handleModalOpen();
                      setModalType("edit");
                      setRoleName(data?.name);
                      setRoleIcon(data?.iconUrl);
                      setSelectedRoleId(data?.id);
                    }}
                  >
                    <Icon sx={{ color: "#fff" }}>edit</Icon>
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      color: "#fff",
                      bgcolor: "#ec4b48",
                    }}
                    size="small"
                    disabled={handleDisabled("delete")}
                    onClick={() => {
                      handleConfirmationToggle();
                      setSelectedRoleId(data?.id);
                    }}
                  >
                    <Icon sx={{ color: "#fff" }}>delete</Icon>
                  </Button>
                </>
              )}
            </>
          ),
        };
      });
    setRows(Array.isArray(customAddRoles) ? customAddRoles : []);
  }, [filteredRoles]);

  useEffect(() => {
    if (search !== "") {
      const foundRoles = allRoles.filter((role) => {
        return (
          role.name.toLowerCase().includes(search.toLowerCase()) ||
          role.id.toString().includes(search)
        );
      });
      setFilteredRoles(foundRoles);
    } else {
      setFilteredRoles(allRoles);
    }
  }, [allRoles, search]);

  useEffect(() => {
    const filteredCategories = allRoles
      .filter((category) => selectedRows.includes(category.id))
      .filter((category) => category.organization_id == null);
    const filteredIds = filteredCategories.map((category) => category.id);
    setwithOutOrgIds(filteredIds);
  }, [selectedRows]);

  useEffect(() => {
    if (pagePermissions !== permission.admin && pagePermissions !== permission.roles) {
      const routeLink = getRouteByNumber(pagePermissions);
      return navigate(routeLink);
    }
    if (featurePermissions) {
      const data = featurePermissionsObject(featurePermissions);
      setStorePermissions(data);
      console.log(data, "featurePermissionsfeaturePermissions");
    }
    dispatch(getAllRole());
  }, []);
  return (
    <>
      {/* <DashboardLayout> */}
      <MDBox pt={3} pb={3}>
        {loading && <Loader />}
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {/* <Card> */}
            <MDBox pt={3}>
              <Box pt={1} px={2}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} lg={6}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="search">Search</InputLabel>
                      <OutlinedInput
                        id="search"
                        type="text"
                        sx={{
                          width: { lg: "50%", xs: "100%" },
                        }}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton aria-label="search" edge="end">
                              <SearchOutlined />
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Search"
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={6}
                    display="flex"
                    justifyContent={{ xs: "center", lg: "flex-end" }}
                    alignItems="center"
                  >
                    <Box>
                      <Tooltip
                        title={
                          withOutOrgIds.length >= 1
                            ? " You can't delete Role without organization Id"
                            : ""
                        }
                        arrow
                        placement="top"
                        disableInteractive={!withOutOrgIds.length >= 1}
                      >
                        <span>
                          <Button
                            variant="contained"
                            sx={{
                              color: "#fff",
                              bgcolor: "#ec4b48",
                              "&:hover": {
                                bgcolor: "darkslategray",
                              },
                              marginRight: "10px",
                              cursor: withOutOrgIds.length >= 1 ? "not-allowed" : "pointer",
                            }}
                            disabled={withOutOrgIds.length >= 1 || handleDisabled("delete")}
                            onClick={() => {
                              if (selectedRows.length > 0) {
                                handleDeleteConfirmationToggle();
                              } else {
                                toast.error("Please select any role for deletion!");
                              }
                            }}
                          >
                            <Icon fontSize="small">delete_forever</Icon> Delete
                          </Button>
                        </span>
                      </Tooltip>

                      <Button
                        variant="contained"
                        sx={{
                          color: "#fff",
                          bgcolor: "#3b8335",
                          "&:hover": {
                            bgcolor: "darkslategray",
                          },
                          marginLeft: { xs: "10px", lg: "0" },
                        }}
                        onClick={() => {
                          handleModalOpen();
                          setModalType("add");
                          setRoleName("");
                        }}
                        disabled={handleDisabled("create")}
                      >
                        <Icon fontSize="small">add</Icon> New
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              {filteredRoles && filteredRoles.length >= 1 ? (
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                  checkboxSelection
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                  checkBoxes={true}
                />
              ) : (
                <MDTypography
                  component="h2"
                  href="#"
                  color="text"
                  fontWeight="bold"
                  sx={{
                    textAlign: "center",
                    my: 10,
                  }}
                >
                  No Roles Exists
                </MDTypography>
              )}
            </MDBox>
            {/* <MDBox
                  m={3}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Pagination count={10} />
                </MDBox> */}
            {/* </Card> */}
          </Grid>
        </Grid>
      </MDBox>
      {/* </DashboardLayout> */}
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid container justifyContent="center" alignItems="center" style={{ minHeight: "100vh" }}>
          <Card sx={styleModal}>
            <Box
              variant="gradient"
              bgcolor="info.main"
              borderRadius="12px"
              boxShadow={3}
              mx={2}
              p={2}
              mb={1}
              textAlign="center"
            >
              <Typography variant="h4" fontWeight="medium" color="white.main" mt={1} mb={1}>
                {modalType === "add" ? "Add New Role" : "Edit Role"}
              </Typography>
            </Box>
            <Box mx={1} p={1} mt={1}>
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  handleSubmit();
                }}
              >
                <Box mb={2}>
                  <MDInput
                    type="text"
                    label="Name"
                    name="name"
                    value={roleName}
                    required
                    onChange={(e) => setRoleName(e.target.value)}
                    fullWidth
                  />
                </Box>
                <Box mb={2}>
                  <Box>
                    <input
                      type="file"
                      accept="image/*"
                      required={modalType === "edit" ? false : true}
                      onChange={(e) => handleFileChange(e)}
                    />
                  </Box>
                </Box>

                <Box mt={4} mb={1} sx={{ display: "flex", gap: "10px", justifyContent: "end" }}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => setModalOpen(false)}
                    disabled={loading}
                  >
                    Cancel
                  </MDButton>
                  <MDButton variant="gradient" color="info" type="submit" disabled={loading}>
                    {modalType === "add" && loading == false
                      ? "Add"
                      : modalType === "add" && loading
                      ? "Adding..."
                      : modalType === "edit" && loading
                      ? "Updating..."
                      : modalType === "edit" && loading == false
                      ? "Update "
                      : ""}
                  </MDButton>
                </Box>
              </form>
            </Box>
          </Card>
        </Grid>
      </Modal>
      <ConfirmationModal
        modalOpen={confirmationModal}
        modalToggle={handleConfirmationToggle}
        onSubmit={handleDelete}
        loading={loading}
        infoText="Deleting a role will also delete all its associated categories and questions."
      />
      <ConfirmationModal
        modalOpen={deleteConfirmationModal}
        modalToggle={handleDeleteConfirmationToggle}
        onSubmit={handleAllDelete}
        loading={loading}
        infoText="Deleting a role will also delete all its associated categories and questions."
      />
    </>
  );
}
