import React, { useEffect } from "react";
import { Box, Avatar, Typography } from "@mui/material";
import moment from "moment";
import adminIcon from "assets/images/crown.png";
import UserImg from "assets/images/logos/user.png";

const MessageComponent = ({ key, comment }) => {
  useEffect(() => {
    if (comment) {
      const element = document.getElementById(comment.created_at);
      if (element) {
        element.parentElement.scrollTo({
          top: element.offsetTop,
          behavior: "smooth",
        });
      }
    }
  }, [comment]);

  return (
    <Box
      key={key}
      id={comment?.created_at}
      sx={{
        backgroundColor: "#f7f7f7",
        py: 2,
        borderRadius: "12px",
        my: 3,
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            width: "7%",
          }}
        >
          {comment?.user_type === "Organization" && (
            <Box
              component="img"
              src={adminIcon}
              alt="Crown Icon"
              sx={{
                position: "absolute",
                top: 0,
                left: "1.2rem",
                transform: "rotate(-18deg) translate(-50%, -50%)",
                width: "1.5rem",
                height: "1.5rem",
              }}
            />
          )}
          <Avatar
            src={comment?.image_url || UserImg}
            alt="Profile Image"
            sx={{ width: 50, height: 50 }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "93%",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6" component="span" sx={{ color: "#000", fontWeight: 500 }}>
              {comment?.user_name}
            </Typography>
            <Typography variant="h6" component="span" sx={{ color: "#000", fontWeight: 400 }}>
              &nbsp;-&nbsp;
              {moment(comment?.created_at).format("hh:mm A")}
            </Typography>
            {comment?.user_type == "Organization" && (
              <Box
                sx={{
                  display: "inline-flex",
                  alignItems: "center",
                  px: 1,
                  paddingTop: "3px",
                  paddingBottom: "3px",
                  backgroundColor: "grey.300",
                  ml: 2,
                  color: "black",
                  fontSize: "0.7rem",
                  fontWeight: "500",
                  borderRadius: "9999px",
                }}
              >
                {comment?.user_type == "Organization" && "Admin"}
              </Box>
            )}
          </Box>
          <Typography
            variant="body1"
            sx={{
              color: "#000",
              mt: { xs: 0 },
              fontWeight: 400,
              lineHeight: 1.5,
            }}
          >
            {comment?.comment}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default MessageComponent;
