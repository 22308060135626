import { useEffect, useRef } from "react";

const useInitScroll = () => {
  const elementRef = useRef(null);

  useEffect(() => {
    if (elementRef.current) {
      elementRef.current.scrollTop = elementRef.current.scrollHeight;
    }
  }, []);

  return elementRef;
};

export default useInitScroll;
