import { Box, Stack } from "@mui/material";
import useInitScroll from "hooks/useInitScroll";
import useScrollToBottom from "hooks/useScrollToBottom";
import { ChatMessageGroupByDate } from "./ChatMessageGroupByDate";
import StickyDate from "./StickyDate";

export const ChatBody = ({ chat }) => {
  const chatEndRef = useScrollToBottom([chat]);
  const initScroll = useInitScroll();

  return (
    <Box className="chat-container bg-white chat-body-border" ref={initScroll}>
      {chat?.map(({ date, groupedMessages }) => (
        <Stack key={date}>
          <StickyDate date={date} />
          <ChatMessageGroupByDate messages={groupedMessages} chatEndRef={chatEndRef} />
        </Stack>
      ))}
    </Box>
  );
};
