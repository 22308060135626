import { Box } from "@mui/material";
import dayjs from "dayjs";
import { ChatAvatar } from "./ChatAvatar";
import { MessageBubble } from "./MessageBubble";

export const ChatMessageGroupByDate = ({ messages, chatEndRef }) => (
  <>
    {messages.map((msg, index) => {
      const { is_my_message, sender_image, created_at, content } = msg;
      const position = is_my_message ? "right" : "left";
      const time = dayjs(created_at + "Z").format("hh:mm A");
      const isFirstInGroup = index === 0 || is_my_message !== messages[index - 1].is_my_message;

      return (
        <Box
          key={index}
          ref={chatEndRef}
          // sx={{ mt: isFirstInGroup ? 3 : 0 }}
          className={`d-flex ${position}`}
        >
          {/* {isFirstInGroup ? (
            <> */}
          <ChatAvatar url={sender_image} />
          <Box className={`message-bubble-${position}`}></Box>
          {/* </>
          ) : (
            <Box sx={{ width: isFirstInGroup ? "auto" : 30, height: 30, mx: 1.5 }} />
          )} */}

          <MessageBubble message={msg} time={time} position={position} />
        </Box>
      );
    })}
  </>
);
